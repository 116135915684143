@import "../../assets/scss/variables.scss";

.video-Section {
  height: 360px;
  width: 100%;
  display: table;
  margin-bottom: 26px;
  background-color: $lightGrey;

  .player-Custom {
    width: 100%;
    padding-top: 0 !important;
    height: 360px !important;


    #player {
      max-width: none;
    }

    button {
      &.video-react-big-play-button {
        border: none;
        background-color: transparent;
        height: 100%;
        width: 100%;
        font-size: 50px;
        top: 0;
        left: 0;

        &.video-react-big-play-button-left {
          &:after {
            content: "\e907";
            font-family: "oralift";
            position: absolute;
            top: 40%;
            left: 50%;
          }

          &:before {
            opacity: 0;
          }
        }
      }
    }
  }
}

.video-Section {
  .player-Custom {
    .vp-center {
      #player {
        max-width: none;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  //   .video-Section {
  //     // width: 90%;
  //   }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .video-Section {
    height: 250px !important;

    .player-Custom {
      // width: 80% !important;
      height: 250px !important;

      button {
        &.video-react-big-play-button {
          &.video-react-big-play-button-left {
            &:after {
              top: 33%;
              left: 43%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .video-Section {
    height: 250px !important;

    .player-Custom {
      // width: 80% !important;
      height: 250px !important;

      button {
        &.video-react-big-play-button {
          &.video-react-big-play-button-left {
            &:after {
              top: 33%;
              left: 43%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .video-Section {
    height: 250px !important;

    .player-Custom {
      // width: 90% !important;
      height: 250px !important;

      button {
        &.video-react-big-play-button {
          &.video-react-big-play-button-left {
            &:after {
              top: 33%;
              left: 43%;
            }
          }
        }
      }
    }
  }
}