@import "../../assets/scss/variables.scss";
.toast-Section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  .toast-z {
    .icon-checked {
      color: $greenColor;
      display: inline-block;
      font-weight: 900;
      vertical-align: middle;
    }
    .errtextSuccess {
      color: $greenColor;
      display: inline-block;
      padding-left: 8px;
    }
    .errtextFail {
      color: $errorRed;
      display: inline-block;
      padding-left: 8px;
    }
    .icon-warning{
      color: $errorRed;
    }
    .icon-checked{
      color: $greenColor;
    }
  }
  .toast {
    background: $whiteColor;
    border: none;
    box-shadow: none;
    padding: 8px 12px;
    border-radius: 0px;
    max-width: inherit;
    display: inline-block !important;
  }
  .toast-Message {
    display: block;
    h5 {
      font-weight: 400 !important;
      padding-left: 10px;
      padding-top: 2px;
    }
    .bgColor {
      width: 30px;
      height: 30px;
      background: $lightBg;
      display: inline-block;
      border-radius: 50%;
      position: relative;
      float: left;
      margin-right: 8px;

      &.icon-checked {
        &:before {
          position: absolute;
          top: 7px;
          left: 7px;
          font-size: $fontSize16 !important;
          color: $greenColor;
        }
      }
    }
  }
}