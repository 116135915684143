@import "../../assets/scss/variables.scss";

.dropdowncomponent{
  width: 100%;
  float: left;
  padding:0px;
  position: relative;
  text-align: left;
  display: table;
  border-bottom: 1px solid $bordeColor;
  .dropdownButtom{
    width: 100%;
    position: relative;
    &:focus{
      outline: none !important;
      box-shadow:none;
    }
   button{
    background: none !important;
    border: none;
    border-radius: 0;
    font-size: $fontSize16;
    color: $innerColor;
    padding-left: 0px;
    font-weight: 500;

   
    &:focus{
      outline: none !important;
      box-shadow:none!important;
    }
    &.dropdown-toggle{
          width: 100%;
      &:after{
        display: none;
      }
      &:before{
        content: '\e903' !important;
        font-family: 'oralift';
        position: absolute;
        right: 0;
        color: $lightGrey;
        font-size: $fontSize12;
      }
    }
    
   }
   &.dropdown{
     .btn{
      font-size: $fontSize14;
      width: 100%;
      text-align: left;
      color: $labelColor;
      padding-left: 0;
      margin: 0;

      @media(max-width:1024px){
        font-size: $fontSize12;
      }
     }
   }
   &.show{
     .dropdown-toggle{
       &:first-child{
        &:before{
          transform: rotate(180deg);
         }
       }
     }
   }
  }
  .dropdown-menu{
   &.show{
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: $bgColor;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    min-width: auto;
    .dropdown-item{
      border-bottom:1px solid $bordeColor ;
      padding-left: 8px;
      color: $innerColor;
      font-size: $fontSize16;
      font-family: $primary-font-family;
      font-weight: 500;
      &:last-child{
        border-bottom:none;
      }
      &:focus{
        outline: none;
      }
       &:active{
        background: transparent;
      }
    }
   }

}
   
}
 @media only screen and (min-width:500px) and (max-width:767px) {
   .dropdowncomponent {
     .dropdown-menu{
       &.show{
          .dropdown-item{
            font-size: $fontSize14;
          }
       }
     }
   }
 }