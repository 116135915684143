@import "./../../../assets/scss/variables.scss";
.main-LeftFace{
    display: inline-flex;
    width: 100%;
    
    .inner-LeftFace{
        // width: calc(100% - 312px);
        // width: -moz-calc(100% - 312px);
        // float: left;
        // display: block;
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            width:100%;
           height:100%;
            display: table;
           .img-Container {
            display: table;
            padding-bottom: 40px;
        }
        .title-face {
            display: inline-flex;
          }
          .divclass {
            &.show {
              position: fixed;
              width: calc(100% - 312px);
              height: calc(100vh - 80px);
              background: #f3f3f314;
              bottom: 0;
            }
          }
          .tool-Tips {
            &.show {
              a {
                span {
                  display: block !important;
                }
              }
            }
            a {
              span {
                display: none !important;
              }
            }
          }
            .content-Section{
                padding-bottom: 25px;
                display: block;
               
            }
            .custom-Ul{
               .cr-slider-wrap{
                margin-top: 15px;
                text-align: center;
                max-width: 465px;
                position: relative;
                display: table;
                width: 100%;
                &:focus{
                    outline: none;
                }
        
               
               }
                .frame-section{
                    .photo-Plugin{
                        background: $lightBg;
                        height: 568px;
                        max-width: 465px;
                        position: relative;
                        display: table;
                        width: 100%;
                        .frame-Img {
                            text-align: center;
                            display: table;
                            margin: 0 auto;
                            padding: 0;
                            height: 100% ;
                            width: 100%;
                            position: relative;
                            overflow: hidden;

                            & > div{
                                img{
                                    position: absolute;
                                top: 0;
                                left: 0;
                                max-width: 100%;
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                                right: 0;
                                bottom: 0;
                                }
                            }
                            .videoimgFrame{
                                position: absolute;
                                top: 0;
                                left: 0;
                                max-width: 100%;
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                                right: 0;
                                bottom: 0;
                            }
                         
                            .frame-ImgSection{
                                height: 100%;
                                width:100%;
                                display: block;
                                &:before{
                                    content:'';
                                    background-image: url('../../../assets/images/leftframe.png');
                                    background-repeat: no-repeat;
                                    left:0px;
                                    right:0;
                                    background-position: center;
                                    position: absolute;
                                    height: 100%;
                                    // background-size: contain;
                                }
                            }
                           
                            .css-1dkwqii{
                                height: 568px;
                                 max-width: 465px; 
                                
                        .css-bdjl4r{
                            height: 568px !important;
                            border: none;
                            box-sizing: border-box;
                            box-shadow: none;
                            color: transparent;
                            overflow: hidden;
                            padding: 25px 0;
                            &:before{
                                border: none;
                                content:'';
                                background-image: url('../../../assets/images/leftframe.png');
                                background-repeat: no-repeat;
                                left:0px;
                                right:0;
                                background-position: center;
                            }
                            &:after{
                                display: none;
                            }
                        }
                            }
                        }
                       
                    }
                    .save-Button{
                        text-align: center;
                        max-width: 465px;
                        position: relative;
                        display: table;
                        width: 100%;
                        .btn{
                            background: transparent!important;
                            border: 1px solid $primaryColor !important;
                            color: $primaryColor;
                            width: 133px;
                            font-size: 12px;
                            height: 32px;
                            line-height: 20px;
                            margin: 0 auto;
                            text-align: center;
                            display: table;
                            margin-top: 15px;
                            margin-bottom: 10px;
                           } 
                    }
                    
                   
                }
                h5{
                    position: relative;
                    padding-left: 20px;
                    padding-bottom: 8px;
                   
                }
                .content-li{
                    padding-left: 0;
                    .info-Section{
                        border-bottom:1px solid $bordeColor;
                        margin-left: 0px;
                       
                        .innerTitle{
                            padding: 0 0 15px 25px;
                            margin: 0;
                            position: relative;
                         
                            .warning-Icon{
                                height: 30px;
                                width:32px;
                                border-radius: 50%;
                                background: $lightBg;
                                position: absolute;
                                left: -17px;
                                top: 0px;
                                &:before{
                                    content: '\e906';
                                    font-family: 'oralift';
                                    position: absolute;
                                    top: 5px;
                                    left: 8px;
                                    color: $secondaryColor;
                                }
                               
                            }
                        }
                    }
                    .skip-Section{
                        display: table;
                        padding: 30px 0 0 40px;
                        .icon-css{
                            padding-left: 8px;
                        }
                    }
                }
               
                .btn{
                    margin-top: 40px;
                    // margin-left: 20px;
                    
                }
                .upload-Button {
                    .submitBtn{
                        img{
                            &.loaderImg{
                                margin-top: -3px;
                            }
                        }
                    }
                    position: relative;
                       input{
                        position: absolute;
                        top: 40px;
                        left: 20px;
                        font-size: 0; 
                        background: transparent;
                        border: none;
                        opacity: 0;
                        width: 260px;
                        border-radius: 45px !important;
                        border: none !important;
                        height: 56px;
                        opacity: 0;
                        cursor: pointer;
                       }
                }

            }
           
           
        }
}
    }
   


    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-LeftFace {
            .inner-LeftFace{
                .main-Container{
                    max-width: 667px;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                    .custom-Ul{
                        .photo-Plugin{
                            height: 501px;
                              max-width: 410px !important;
                          
                            .frame-Img {
                                padding: 20px 0;
                                img{
                                    width:90%;
                                }
                            }
                        }
                        .content-li {
                            padding: 0;
                            .info-Section{
                                margin-left: 13px;
                                .innerTitle{
                                    padding: 0 0 15px 23px;
                                }
                            }  
                        }
                        
                        .btn{
                            margin-left: 20px;
                        }
                        .cr-slider-wrap{
                            .cr-slider {
                                width: 246px;
                               
                            }
                           
                           }
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {

        .main-LeftFace {
            display: block !important;
            .inner-LeftFace{
                width: 100% !important;
                padding-top: 15px;
                padding-top: 15px;
                padding-bottom: 55px;
                display: block;
                .main-Container{
                    max-width: 667px;
                    padding: 0px 0 20px 0;
                    .custom-Ul{
                        .frame-section{
                        .photo-Plugin{
                            height: 398px !important;
                            max-width: 326px !important;
                            .frame-Img {
                                padding: 0!important;
                                .frame-ImgSection{
                                    &:before{
                                        height: 96% !important;
                                        top: 2%;
                                        background-size: contain;
                                    }
                                }
                                .css-1dkwqii{
                                    height: 398px  !important;
                            .css-bdjl4r{
                                height: 398px  !important;
                                &:before{
                                    height: 96% !important;
                                    top: 2%;
                                    background-size: contain;
                                }
                               
                            }
                                }
                            }
                            
                        }
                        .save-Button{
                            max-width: 326px;
                        }
                    }
                        .info-Section{
                            margin-left: 20px;
                        } 
                    
                        .btn{
                            margin-left: 20px;
                        }
                        .cr-slider-wrap{
                            max-width: 326px;
                            .cr-slider{
                                width:246px;
                            }
                        }
                       
                    }
                }
            }
        }
    }
    
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-LeftFace {
            display: block !important;
            .inner-LeftFace{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 55px;
                display: block;
                .main-Container{
                    max-width: 625px;
                    padding: 0px 0 20px 0;
                    .custom-Ul{
                        padding-bottom: 0;
                        .frame-section{
                        .photo-Plugin{
                            height: 398px  !important;;
                           max-width:326px  !important;
                            .frame-Img {
                                padding: 15px 0 !important;
                                .frame-ImgSection{
                                    &:before{
                                        height: 96% !important;
                                        top: 2%;
                                        background-size: contain;
                                        width: 94%;
                                        left: 4%;
                                    }
                                }
                                .css-1dkwqii{
                                    height: 398px  !important;
                            .css-bdjl4r{
                                height: 398px  !important;
                                &:before{
                                    height: 96% !important;
                                    top: 2%;
                                    background-size: contain;
                                    width: 94%;
                                    left: 4%;
                                }
                               
                            }
                                }
                            }
                        }
                    }
                        .content-li{
                            padding-left: 15px;
                            .info-Section{
                                margin-left: 20px;
                                .innerTitle{
                                    font-size: $fontSize13;
                                }
                            } 
                        }
                      
                        .btn{
                            margin-left: 20px;
                        }
                        .cr-slider-wrap{
                            max-width: 326px;
                            .cr-slider{
                                width:246px;
                            }
                        }
                    }
                  
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-LeftFace {
            display: block !important;
            .inner-LeftFace{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 55px;
                display: block;
                 .upload-Scroll{
                    height: calc(100vh - 180px) !important;
                }
                .main-Container{
                    max-width: 442px;
                    padding: 20px 0 20px 0;
                    .custom-Ul{
                        padding-bottom: 0;
                        .frame-section{
                            margin-bottom: 20px;
                            .photo-Plugin{
                                height: 437px !important;
                                max-width: 358px !important;
                                margin-right: 0px;
                                .frame-Img {
                                    .frame-ImgSection{
                                        &:before{
                                            height: 96% !important;
                                            top: 2%;
                                            background-size: contain;
                                            width: 94%;
                                            left: 4%;
                                        }
                                    }
                                    .css-1dkwqii{
                                        height: 437px  !important;
                                .css-bdjl4r{
                                    height: 437px  !important;
                                    &:before{
                                        height: 96% !important;
                                        top: 2%;
                                        background-size: contain;
                                        width: 94%;
                                        left: 4%;
                                    }
                                   
                                }
                                    }
                                }
                            }
                            .save-Button{
                                max-width: 326px;
                            }
                        }
                      
                        .info-Section{
                            margin-left: 0px;
                            .innerTitle{
                                font-size: $fontSize13;
                            }
                        } 
                        .btn{
                            margin-left: 20px;
                        }
                        .cr-slider-wrap{
                            max-width: 326px;
                            .cr-slider{
                                width:246px;
                            }
                        }
                    }
                }
            }
        }
    }
