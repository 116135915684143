@import "../../assets/scss/variables.scss";
.main-ProgressData{
     h5{
              color: $innerColor;
              font-size: 16px ;
              font-weight: 400;  
              line-height: 0 !important;
              padding-bottom: 20px;
              
        } 
.weekly-List{
      display: flex;
      flex-direction: row;
          padding-bottom: 33px;
    .list-Item{
            display: block;
    padding-right: 8px;
        .bg-Color{
             height: 50px;
             width: 50px;
        border-radius: 50%;        
       //background: '#6c757d';
         position: relative;

        &.progessdefault{
          background: $lightBg;
        }
        &.progresslightBg{
          background: $secondaryColor;
          color: white;
        }
            .weeks-No{
                color: $whiteColor;
                font-size: 16px ;
                font-weight: 400;
                position:absolute;
                top: 13px;
                left: 0;
                text-align: center;
                right: 0;
                cursor: pointer;
            }
            &.monthsBox{
                border-radius: 5px;
                height: 40px;
                width: 57px;
                border: 1px solid #C0C0C0;
                  .weeks-No{
                          top: 7px;
                        left: 0;
                        right: 0;
                         color: $innerColor;
                         
                  }
                  &.progessdefault{
                    background: $lightBg;
                  }
                  &.progresslightBg{
                    background: $secondaryColor;
                    .weeks-No{
                      color: white!important;
                    }
                  }
            }
             &.monthlyBox{
                border-radius: 5px;
                height: 40px;
                width: 98px;
                border: 1px solid #C0C0C0;
                  .weeks-No{
                          top: 7px;
                        left: 0;
                        right: 0;
                         color: $innerColor;
                         
                  }
                  &.progessdefault{
                    background: $lightBg;
                  }
                  &.progresslightBg{
                    background: $secondaryColor;
                    .weeks-No{
                      color: white!important;
                    }
                  }
            }
        }
        div{
          cursor: pointer;
        }
    }
  
}
}