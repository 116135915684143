@import "./../../../assets/scss/variables.scss";
.main-Photos {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-Photos {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 32px 0 0px 0;
      width: 100%;
      display: table;
      .tooltip_main{
        display: inline-block;
        float: right;
        .divclass{
          &.show {
            position: fixed;
            width: calc(100% - 312px);
            height: calc(100vh - 80px);
            background: #f3f3f314;
            bottom: 0;
          }
        }
        .tool-Tips {
          
          &.show{
            a {
              span{
                display: block !important; 
                text-align: left;
               }
          }
          }
          a {
              span{
                display: none !important; 
               }
          }
          a.tip {
            width: 30px;
            height: 30px;
           
            &.icon-info {
              &:before {
                padding: 4px 6px;
              }
            }
          }
        }
      }
      .Nodata {
        position: absolute;
        top: 50% !important;
        left: 55%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
          font-size: $fontSize24;
          color: $secondaryColor;
          padding-top: 20px;
        }
      }
      .photo-Scrolls {
        height: calc(100vh - 240px) !important;
       

        .date-wise-Photos {
          display: block;
        }
        .main-photo-Section {
          display: block;
          width: 100%;
          // overflow: hidden;
          .main-Img-Section {
            margin-right: 0;
            margin-bottom: 30px;
            display: flex;
            position: relative;
            .preview-Section {
              display: table;
              // width: 305px;
              @media (max-width: 1024px) {
                padding: 0 5px;
              }

              img {
                width: 145px;
                height: 145px;
                object-fit: cover;
                // max-width: 100%;

                @media (max-width: 1024px) {
                  width: 100%;
                }
              }
              .link-Section {
                display: table;
                width: 100%;
                padding-top: 10px;
                .innerTitle {
                  font-size: $fontSize20;
                }
              }
             
            }
          }
          .select-Photo {
            position: absolute;
            right: 25px;
            top: 15px;
          }
        }
      }
      .compare-Button {
        // position: absolute;
        // bottom: 20px;
        // left: 50%;
        //  transform: translate(-50%, 0);
        .submitBtn{
          float: none;
          margin-top: 0;
          display: block;
          margin: 0 auto;
        }
        .icon-css {
          color: #ffffff !important;
          font-size: 16px;
          padding-left: 9px;
          padding-top: 5px;
          display: inline-block;
        }
      }
      .add-Photos {
        text-align: right;
        .upload-Button {
          position: relative;
          span {
            float: left;
            border: 1px solid #3776fd;
            box-sizing: border-box;
            border-radius: 45px;
            color: #3776fd;
            padding: 6px 16px;
            margin-bottom: 20px;
            cursor: pointer;
          }

          @media (max-width: 1024px) {
            padding-bottom: 30px;
          }
          .addphotos {
            .btn {
              margin: 0;
              background: transparent;
              border: 1px solid $blueColor !important;
              color: $blueColor;
              height: 32px;
              width: 133px;
              padding: 0;
              float: right;
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 0px;
      margin-top: 26px;
    }
  }
  .date-Section {
    display: table;
    width: 99%;
    margin-bottom: 15px;
    border-bottom: 1px solid $bordeColor;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .main-Photos {
    .inner-Photos {
      .main-Container {
        max-width: 733px;
        // padding: 20px 0 40px 0;
        .photo-Scrolls {
          .main-photo-Section {
            .select-Photo{
              right: 45px;
            }
            .col-xl-2 {
              flex: 0 0 33.333333%;
              max-width: 33.333333%;
            }
            .main-Img-Section {
              .preview-Section {
                width: 215px;
                height: 215px;
                img {
                  width: 210px;
                  height: 210px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .main-Photos {
    .inner-Photos {
      .main-Container {
        max-width: 667px;
        .photo-Scrolls {
          .main-photo-Section {
            .main-Img-Section {
              .preview-Section {
                width: 215px;
                height: 215px;
                img {
                  width: 210px;
                  height: 210px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Photos {
    display: block !important;
    .inner-Photos {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        max-width: 667px;
        // padding: 20px 0 40px 0;

        .btn {
          float: left;
        }
        .main-Img-Section {
          .preview-Section {
            width: 215px;
            height: auto;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Photos {
    display: block !important;
    .inner-Photos {
      width: 100% !important;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        width: 625px;
        // padding: 10px 0 20px 0;
        .main-Img-Section {
          .preview-Section {
            width: 201px;
            height: auto;
          }
        }
        // .compare-Button {
        //   left: 30%;
        // }
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Photos {
    display: block !important;
    .inner-Photos {
      width: 100% !important;
      // padding-top: 15px;
      // padding-bottom: 55px !important;;
      display: block !important;
      .review-Scroll {
        height: calc(100vh - 180px) !important;
      }
      .main-Container {
        width: 452px !important;
              
        // padding: 10px 0 20px 0;
        .main-Img-Section {
          .preview-Section {
            width: 215px;
            height: auto;
          }
        }
        // .compare-Button {
        //   left: 25%;
        // }
      }
    }
  }
}
 .photo-Scrolls >div>div{
   top: 35px !important;
 }