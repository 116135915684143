@import "./../../../assets/scss/variables.scss";
.main-Thankyou{
    display: inline-flex;
    width: 100%;
    .inner-Thankyou{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 210px 0 0px 0;
            width:100%;
            // display: table;
            .img-Container{
                width: 140px;
                height: 126px;
                padding-bottom: 40px;
                display: table;
            }
           
            .content-Section {
                display: table;
                padding-top: 15px;
                padding-bottom: 10px;
                .paddingB15{
                    padding-bottom: 24px;
                }
               
             }
             .aLink{
                 font-weight: 300!important;
                 padding-top: 0;
                .icon-css{
                      color:$blueColor;
                      padding-left: 10px;
                }
             }
          
           
        }
        .btn{
            margin-bottom: 0px;
        }
}

    }
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-Thankyou {
            .inner-Thankyou{
                .main-Container{
                    max-width: 731px;
                    padding: 220px 0 0 0;
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-Thankyou {
            .inner-Thankyou{
                .main-Container{
                    max-width:667px;
                    padding: 220px 0 0x 0;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-Thankyou {
            display: block !important;
            .inner-Thankyou{
                width: 100%;
                padding-top: 15px;
                display: table!important;
                .main-Container{
                    max-width: 667px;
                    padding: 220px 0 0 0;
                
                    
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-Thankyou {
            display: block !important;
            .inner-Thankyou{
                width: 100% !important;;
                padding-top: 15px;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding:200px 0 0 0;
                   
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-Thankyou {
            display: block !important;
            .inner-Thankyou{
                width: 100% !important;
                padding-top: 15px;
                display: table!important;
                .main-Container{
                    width: 452px !important;
                    padding: 150px 0 0 0;
                     h2 {
                    font-size: 22px !important;
                    }
                  
                }
            }
        }
    }