@import "./../../../assets/scss/variables.scss";
.main-photoInfoPage{
    display: inline-flex;
    width: 100%;
    .inner-photoInfo{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 170px 0 150px 0;
            width:100%;
            // display: table;
            .img-Container{
                padding-bottom: 40px;
                display: table;
            }
            .content-Section{
                padding-bottom: 15px;
                
            }
            .icon-css{
                padding-left: 7px;
            }
     
           
        }
        .btn{
            margin-bottom: 0px;
        }
}

    }
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-photoInfoPage {
            .inner-photoInfo{
                .main-Container{
                    max-width: 731px;
                    // padding: 120px 0 130px 0;
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-photoInfoPage {
            .inner-photoInfo{
                .main-Container{
                    max-width: 667px;
                    margin-left: 95px;
                    // padding: 120px 0 130px 0;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-photoInfoPage {
            display: block !important;
            .inner-photoInfo{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 170px 0 150px 0;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 0;
                        width: 60%;
                        float: left;
                        padding-left: 61px;
                    }
                    
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-photoInfoPage {
            display: block !important;
            .inner-photoInfo{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                   max-width: 625px;
                   padding: 160px 0 185px 0;
                    margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 25px;
                        width: 100%;
                        float: left;
                        padding-left: 0;
                    }
                   
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-photoInfoPage {
            display: block !important;
            .inner-photoInfo{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;;
                display: block !important;;
                .main-Container{
                    width: 452px !important;
                    padding: 100px 0 100px 0;
                    margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 25px;
                        width: 100%;
                        float: left;
                        padding-left: 0;
                    }
                }
            }
        }
    }