@import "./../../../assets/scss/variables.scss";

.main-AdminLogin {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  position: relative;
 .inner-AdminLogin{
     display: inline-block;
    width: 100%;
    height: 100vh;
    .main-Container {
      position: absolute; 
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: $whiteColor;
      height: 535px;
      width: 384px;
      padding: 30px 40px;
      text-align: center;
      .img-upload {
        padding-bottom: 32px;
        .profileimg {
          height: 146px;
          width: 155px;
          align-items: center;
          align-self: center;
          border: 1px solid gray;
        }
      }
      h4{
        color: $secondaryColor;
        text-align: left;
        padding-bottom: 24px;
      }
      .form{
          .form-group {
          margin-bottom: 17px;
        }
        button{
              width: 100%;
              height: 48px;
              margin-top: 15px;
        }
        .aLink{
          font-size: 14px !important;
          color: $blueColor;
          font-weight: 400 !important;
          cursor: pointer;
          padding-top: 16px;
          display: table;
          text-align: center;
          margin: 0 auto;
        }
      }
    }
  }
  .toast-Section{
    // max-width: fit-content !important;
    top: 40px;
      display: inline-block;
  }
}
::-webkit-scrollbar { 
    display: none; 
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-AdminLogin {
   .inner-AdminLogin{
      .main-Container {
        // width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-AdminLogin {
   .inner-AdminLogin{
      .main-Container {
        // width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-AdminLogin {
    display: block !important;
   .inner-AdminLogin{
      width: 100%;
      display: block !important;
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-AdminLogin {
    // display: block !important;

   .inner-AdminLogin{
      // width: 100% !important;
      // display: block !important;

      .main-Container {
        // width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-AdminLogin {
    // display: block !important;
   .inner-AdminLogin{
      // width: 100% !important;
      // display: table !important;

      .main-Container {
        // width: 385px !important;
      }
    }
  }
}
