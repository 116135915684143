@import "../../assets/scss/variables.scss";
.headerhome{
    display: block;
    width: 70%;
    height: 80px;
    background: $whiteColor;
    float: left;
    width: calc(100% - 312px);
    border-left: 1px solid $bgColor;
    display: inline-block;
    .icon-menu{
        position: absolute;
        top: 20px;
        left: 26px;
        font-size: 19px;
        cursor: pointer;
        display: none;
    }
    .main-header{
        float: right;
        display: inline-block;
        padding: 15px 0;
        padding-right: 2%;
    .notifications{
            display: table;
             float: left;
            padding-top:14px;
            padding-right: 55px;
            position: relative;
             .icon-css{
                 color:$innerColor;
                 font-size: $fontSize24;
                 cursor: pointer;
             }
             .badge-Count{
                 height: 18px;
                 width: 18px;
                 border-radius: 50%;
                 font-size: $fontSize12;
                 background: $primaryColor;
                 font-family: $primary-font-family;
                 font-weight: 600;
                 color: $whiteColor;
                 position: absolute;
                 right: 45px;
                 top: 10px;
             }
    
    }
        .profile-section {
            display:table ;
            width: 100%;
            .profile-Img{
                 width: 50px;
                height: 50px;
                margin-right: 15px;
           
            img{
                    padding-right: 0px;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
            
            }
           
        }
         .userName{
                vertical-align: middle;
                display: table-cell;
            }
        }
}

}
@media only screen and (max-width:1024px) {
    .headerhome{
       width: 100%;
          .icon-menu{
              display: block;
          }
    }
}



