@import "../../assets/scss/variables.scss";
.stepcustomModal{
    .modal-dialog{
        max-width: 395px;
        .modal-content{
                padding: 0;
                border:none;
                border-radius: 10px;
            .modal-body{
                text-align: center;
                max-width: 100%;
                margin: 0 auto;
                display: table;
                padding: 16px 28px;
                
                h4{
                    color:$secondaryColor !important;
                    
                }
            }
            .modal-footer{
                border:none;
                padding: 16px 0;
                text-align: center;
                display: table;
                border-top: 1px solid #d5d5d5;
                .submitBtn{
                    width: auto;
                    height: auto;
                    background: transparent;
                    color: $darkpurple;
                    font-size: $fontSize14;
                }
            }
        }
    }
}

