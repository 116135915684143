@import "./assets/scss/variables.scss";

body {
  margin: 0px !important;
  padding: 0px !important;
  font-family: $primary-font-family !important;
  // font-size: $fontSize14 !important;
  // color: $colorLightBlack !important;
  overflow: hidden;
  background: $whiteColor !important;
}

* {
  box-sizing: border-box;
}

// fonts-family 
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts//Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('./assets/fonts//Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Roboto-Medium.woff') format('woff'),
    url('./assets/fonts/Roboto-Medium.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Roboto-Light.woff') format('woff'),
    url('./assets/fonts/Roboto-Light.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Roboto-Bold.woff') format('woff'),
    url('./assets/fonts/Roboto-Bold.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

// fonts icon
@font-face {
  font-family: 'oralift';
  src: url('./assets/fonts/oralift.eot?jic3mg');
  src: url('./assets/fonts/oralift.eot?jic3mg#iefix') format('embedded-opentype'),
    url('./assets/fonts/oralift.ttf?jic3mg') format('truetype'),
    url('./assets/fonts/oralift.woff?jic3mg') format('woff'),
    url('./assets/fonts/oralift.svg?jic3mg#oralift') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'oralift' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-checked:before {
  content: "\e901";
}

.icon-box:before {
  content: "\e900";
}

.icon-close:before {
  content: "\e902";
}

.icon-down:before {
  content: "\e903";
}

.icon-menu:before {
  content: "\e904";
}

.icon-right:before {
  content: "\e905";
}

.icon-warning:before {
  content: "\e906";
}

.icon-play-button:before {
  content: "\e907";
}

.icon-stop:before {
  content: "\e908";
}

.icon-play:before {
  content: "\e909";
}

.icon-click:before {
  content: "\e90a";
}

.icon-tab:before {
  content: "\e90b";
}
.icon-bell:before {
  content: "\e90c";
}
.icon-compare:before {
  content: "\e90d";
}
.icon-camera:before {
  content: "\e90e";
}
.icon-info:before {
  content: "\e90f";
}
.icon-mail:before {
  content: "\e910";
}
.icon-eyeoff:before {
  content: "\e911";
}
.icon-eye:before {
  content: "\e912";
}
.icon-userrecent:before {
  content: "\e913";
}
.icon-userno:before {
  content: "\e914";
}
.icon-useractive:before {
  content: "\e915";
}
.icon-useractive1:before {
  content: "\e916";
}
.list-iconlink{
  cursor: pointer;
}
a:focus{
  outline: none;
}