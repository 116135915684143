@import "./../../../assets/scss/variables.scss";

.main-AdminProfile {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-Profile {
    height: calc(100vh - 80px);
    display: inline-block;
    width: 100%;
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 40px 0 10px 0;
      width: 100%;
      position: absolute; /*it can be fixed too*/
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: $whiteColor;
      height: 482px;
      width: 384px;
      padding: 40px;
      text-align: center;
      .img-upload {
        // display: flex;
        // flex-direction: column;
        // margin-bottom: 26px;
        margin-bottom: 25px;
        height: 126px;
        width: 96px;
        margin: 0 auto;
        .profile-img{
          width: 100px;
          height: 100px;
           align-items: center;
          align-self: center;
          border-radius: 50%;
          .profileimg {
           width: 100%;
           height: 100%;
           border-radius: 50%;
           object-fit: cover;
           max-width: 100%;
           object-position: center;
          border: 1px solid gray;

          }
        }
        .img-edit {
          width: 36px;
          border-radius: 25px;
          height: 36px;
          background-color: $bgColor;
          align-items: center;
          display: flex;
          align-self: center;
          justify-content: center;
          margin-top: -20px;
          cursor: pointer;

          .cameraclass {
            color: $secondaryColor;
          }
          label {
            margin: 0;
            cursor: pointer;
          }
          & > input {
            display: none;
            cursor: pointer;
            height: 100%;
            width: 100%;
          }
        }
      }
      .form{
        .form-group{
              margin-bottom: 17px;
          &:last-child{
            margin-bottom: 24px;
          }
        }
      }
        .aLink{
          float: left;
          padding: 0;
        }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-AdminProfile {
    .inner-Profile {
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-AdminProfile {
    .inner-Profile {
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-AdminProfile {
    // display: block !important;
    .inner-Profile {
      width: 100%;
      display: block !important;
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-AdminProfile {
    // display: block !important;

    .inner-Profile {
      width: 100% !important;
      display: block !important;

      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-AdminProfile {
    // display: block !important;
    .inner-Profile {
      width: 100% !important;
      display: table !important;

      .main-Container {
        width: 385px !important;
      }
    }
  }
}
