@import "./../../../assets/scss/variables.scss";
.main-QuestionPageStep2{
    display: inline-flex;
    width: 100%;
    .questionPage{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
         width: 100% !important;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 65px 0 79px 0;
            width: 100%;
            display: table;
            .img-Container{
                width: 140px;
                height: 126px;
                padding-bottom: 40px;
                display: table;
            }
            .content-Section{
                padding-bottom: 25px;
            }
            .checkbox-Section{
                display: table;
                width:100%;
                margin-bottom: 30px;
                label{
                    display: block;
                    position: relative;
                    font-size: $fontSize16 !important;
                    color:$innerColor;
                    font-weight: 400 !important;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    &.cust-Label{
                        font-size: $fontSize14 !important;
                        color: $labelColor!important;
                        font-weight: 400!important;
                        display: table!important;
                        margin: 0!important;
                        padding-top: 16px;
                        
                    }
                }
                span.label-Css {
                    background: transparent !important;
                    border: none !important;
                    display: table !important;
                    width: 100% !important;
                    position: relative;
                    padding-left: 35px;
                }
                .textarea {
                    resize: none;
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #d5d5d5;
                    background: transparent;
                    padding: 0;
                    margin-bottom: 10px;
                    &:focus{
                        outline: none;
                    }
                }
                .on-Type {
                    display: block;
                    padding-top: 25px;
                    display: none; 
                }
                .col-lg-6.col-sm-12{
                    padding-bottom: 10px;
                }
            }
           
        }
        .btn{
            // margin-bottom: 20px;
        }
}

    }
  
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-QuestionPageStep2 {
            .questionPage{
                .main-Container{
                    max-width: 731px;
                    padding: 45px 0 79px 0;
                    .question-Form{
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-QuestionPageStep2 {
            .questionPage{
                .main-Container{
                    max-width: 667px;
                    padding: 45px 0 79px 0;
                    .content-Section{
                        padding-bottom: 15px !important;
                    }
                    .question-Form{
                        padding-bottom: 22px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-QuestionPageStep2 {
            display: block !important;
            .questionPage{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 40px 0 51px 0;
                
                    .checkbox-Section{
                        margin-bottom: 25px !important;
                    }
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-QuestionPageStep2 {
            display: block !important;
            .questionPage{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    max-width: 625px;
                    padding: 20px 0 20px 0;
                    .checkbox-Section{
                        margin-bottom: 25px !important;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-QuestionPageStep2 {
            display: block !important;
            .questionPage{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;;
                display: block !important;
                .question-Scroll {
                 height: calc(100vh - 150px) !important;
                }
                .main-Container{
                    max-width: 452px !important;
                    padding: 20px 0 20px 0;
                    .btn{
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }