@import "../../assets/scss/variables.scss";
.textFiled{
  display: table;
  width: 100%;
   position: relative;
  &:focus{
    outline: none !important;
    box-shadow:none;
  }
  .form-label{
    margin: 0;
    color:$labelColor;
  }
  .form-control{
    background: none !important;
    border: none !important;
    border-bottom:1px solid $bordeColor !important;
    border-radius: 0;
    padding: 0;
    font-size: $fontSize16;
    color: $innerColor;
    font-weight: 500;
    font-family: $primary-font-family;
    -moz-appearance: none;
-webkit-appearance: none;

   
    &:focus{
      outline: none !important;
      box-shadow:none;
    }
  }
  .icon-custom{
        z-index: 9;
    font-size: 16px;
    position: absolute;
    right: 11px;
    bottom: 12px;
        color: $placeholder;
    font-weight: 600;
  }
  // readOnly css //@extend
.form-control[readOnly] {
        border: none !important;
}

}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/**For Firefox **/
input[type='number'] {
    -moz-appearance:textfield;
}

/** Webkit browsers like Safari and Chrome **/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}