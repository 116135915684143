@import "./../../../assets/scss/variables.scss";

.main-5TouchTest {
    display: inline-flex;
    width: 100%;

    .inner-5TouchTest {
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;

        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container {
            max-width: 945px;
            margin: 0 auto;
            padding: 40px 0 10px 0;
            width: 100%;
            display: table;

            .tool-Tips{
                .aLink{
                    font-size: 13px !important;
                    line-height: 13px;
                    padding: 0;
                }
            }
            .info-detail{text-align: left;}

            .timer-Progessbar {
                position: relative;
                display: table;
                margin: 0 auto;
                // padding-top: 90px;

            }

            .paddingB {
                padding-bottom: 90px;
            }

            .bottom-Section {
                padding-top: 32px;

                span {
                    display: block;
                }

                .aLink {
                    padding-top: 0;
                    padding-bottom: 10px;
                }
                .disableLink{
                    padding-top: 0;
                    padding-bottom: 10px;
                    cursor: default;       
                }
            }

            // .btn {

            //     position: relative;
            //     margin-top: 30px;

            //     .icon-css {
            //         color: $whiteColor;
            //         font-size: $fontSize16;
            //         padding-left: 9px;
            //         padding-top: 5px;
            //         display: inline-block;

            //     }
            // }

        }

        .btn {
            margin-bottom: 0px;
        }
    }

}

@media only screen and (min-width:1280px) and (max-width:1450px) {
    .main-5TouchTest {
        .inner-5TouchTest {
            .main-Container {
                max-width: 731px;
                padding: 70px 0 72px 0;
            }
        }
    }
}

@media only screen and (min-width:1025px) and (max-width:1281px) {
    .main-5TouchTest {
        .inner-5TouchTest {
            .main-Container {
                max-width: 667px;
                // margin-left: 95px;
                padding: 70px 0 72px 0;

            }
        }
    }
}

@media only screen and (min-width:993px) and (max-width:1024px) {
    .main-5TouchTest {
        display: block !important;

        .inner-5TouchTest {
            width: 100%;
            padding-top: 15px;
            padding-bottom: 55px !important;
            display: block !important;

            .main-Container {
                max-width: 667px;
                padding: 85px 0 0 0;

                .paddingB {
                    padding-bottom: 30px;
                }

                .btn {
                    margin-top: 15px;
                }


            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .main-5TouchTest {
        display: block !important;

        .inner-5TouchTest {
            width: 100% !important;
            ;
            padding-top: 15px;
            padding-bottom: 55px !important;
            display: block !important;

            .main-Container {
                width: 625px;
                padding: 85px 0 0px 0;


            }
        }
    }
}

@media only screen and (min-width:500px) and (max-width:767px) {
    .main-5TouchTest {
        display: block !important;

        .inner-5TouchTest {
            width: 100% !important;
            padding-top: 15px;
            padding-bottom: 0px !important;
            display: table !important;

            .main-Container {
                width: 452px !important;
                padding: 30px 0 0px 0;

                .paddingB {
                    padding-bottom: 40px;
                }


            }
        }
    }
}