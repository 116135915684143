@import "./../../../assets/scss/variables.scss";

.main-AdminChangepwd {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-Profile {
    height: calc(100vh - 80px);
    display: inline-block;
    width: 100%;
     .main-Container {
      position: absolute; 
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      // top: 50%;
      // bottom: 50%;
      // transform: translate(0, -50%);
      margin: auto;
      background-color: $whiteColor;
      height:622px;
      width: 384px;
      padding: 30px 40px;
      text-align: center;
      .img-upload {
        padding-bottom: 22px;
        .profileimg {
          height: 146px;
          width: 155px;
          align-items: center;
          align-self: center;
          border: 1px solid gray;

        }
      }
      h4{
        color: $secondaryColor;
        text-align: left;
        padding-bottom: 8px;
      }
        h5{
        text-align: left;
        padding-bottom: 24px;
      }
      .form{
          .form-group {
          margin-bottom: 16px;
        }
        button{
              width: 100%;
              height: 48px;
              margin-top: 15px;
        }
        .aLink{
          font-size: 14px !important;
          color: #3776FD;
          font-weight: 400 !important;
          cursor: pointer;
          padding-top: 16px;
          display: table;
          text-align: center;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-AdminChangepwd {
    .inner-Profile {
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-AdminChangepwd {
    .inner-Profile {
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-AdminChangepwd {
    // display: block !important;
    .inner-Profile {
      width: 100%;
      display: block !important;
      .main-Container {
        width: 385px;
        margin: 30px auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-AdminChangepwd {
    // display: block !important;

    .inner-Profile {
      width: 100% !important;
      display: block !important;

      .main-Container {
        width: 385px;
        margin: 30px auto;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-AdminChangepwd {
    // display: block !important;
    .inner-Profile {
      width: 100% !important;
      display: table !important;

      .main-Container {
        width: 385px !important;
        margin: 30px auto;
      }
    }
  }
}
