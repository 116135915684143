@import "./../../assets/scss/variables.scss";

.main-ViewReport {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-Profile {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);
  
    .main-Container {
        padding: 150px 0 0 0;
     max-width: 945px;
    margin: 0 auto;
    padding: 150px 0 0 0;
    width: 100%;
     .img-Container {
        width: 140px;
        height: auto;
        padding-bottom: 40px;
        display: table;
      }
      .viewReport{
       
        border:1px solid $primaryColor;
        .headUl{
              padding: 0;
          display: flex;
          list-style: none;
              margin: 0;
              border-bottom:1px solid $primaryColor;
              background: $primaryColor;

              
          .heading{
            width: 25%;
            text-align: center;
            border-right: 1px solid;
            color:$whiteColor;
                padding: 10px 0;
            &:nth-child(1){
              width: 10%;
            }
            &:nth-child(3){
              width: 35%;
            }
            &:nth-child(4){
              border:none;
                width: 35%;
            }
          }
        }
        .dataUl{
          padding: 0;
          display: flex;
          list-style: none;
              margin: 0;
                border-bottom:1px solid $primaryColor;
                   
              .heading{
            width: 25%;
            text-align: center;
            border-right: 1px solid $primaryColor;
                color: $secondaryColor;
                
               align-items: center;
    display: flex;
    justify-content: center;
    
            &:nth-child(1){
              width: 10%;
            }
            &:nth-child(3){
              width: 35%;
            }
            &:nth-child(4){
              border:none;
                width: 35%;
            }
            .spanLi{
              padding: 0 10px;
            }
          }
          &:last-child{
              border:none;
            }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-ViewReport {
    .inner-Profile {
      .main-Container {
        width: 945px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-ViewReport {
    .inner-Profile {
      .main-Container {
        width: 645px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-ViewReport {
    display: block !important;
    .inner-Profile {
      width: 100%;
      display: block !important;
      .main-Container {
        width: 945px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-ViewReport {
    display: block !important;

    .inner-Profile {
      width: 100% !important;
      display: block !important;

      .main-Container {
        width: 745px;
      }
    }
  }
}

@media only screen and (min-width: 650px) and (max-width: 767px) {
  .main-ViewReport {
    display: block !important;
    .inner-Profile {
      width: 100% !important;
      display: table !important;

      .main-Container {
        width: 645px !important;
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 649px) {
  .main-ViewReport {
    display: block !important;
    .inner-Profile {
      width: 100% !important;
      display: table !important;
      .main-Container {
        width: 500px !important;
      }
    }
  }
}
