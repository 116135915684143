@import "../../assets/scss/variables.scss";
.overlay-Main{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    opacity: .6;
    z-index: 1;
    .inner-Overlay{
        justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
        margin: 0 50px;
        .overlay-Text{
            font-size: 38px;
            color: $innerColor;
           .appLink{
                // color: $primaryColor;
                color: #3776fd;
                
            }
        }
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1366px) {
    .overlay-Main{
    .inner-Overlay{
        .overlay-Text{
            font-size: 35px;
        }
    }
}
}
@media only screen and (min-width: 1025px) and (max-width: 1281px) {
    .overlay-Main{
    .inner-Overlay{
        .overlay-Text{
            font-size: 29px;
        }
    }
}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
    .overlay-Main{
    .inner-Overlay{
        margin:0;
        .overlay-Text{
            font-size: 28px;
        }
    }
}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .overlay-Main{
    .inner-Overlay{
        .overlay-Text{
            font-size: 28px;
                text-align: center;
        }
    }
}
}
@media only screen and (min-width: 688px) and (max-width: 767px) {
    .overlay-Main{
    .inner-Overlay{
        .overlay-Text{
            font-size: 28px;
                text-align: center;
        }
    }
}
}
@media only screen and (min-width: 500px) and (max-width: 687px) {
    .overlay-Main{
    .inner-Overlay{
       .overlay-Text{
            font-size: 28px;
                text-align: center;
        }
    }
}
}