@import "./../../../assets/scss/variables.scss";
.main-TouchTest{
    display: inline-flex;
    width: 100%;
    .inner-TouchTest{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 70px 0 70px 0;
            width:100%;
            display: table;
            .img-Container{
                padding-bottom: 40px;
                display: table;
            }
            .content-Section{
                padding-bottom: 15px;
            }
          
           
        }
        .btn{
            margin-top: 14px;
            margin-bottom: 0px;
        }
}

    }
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-TouchTest {
            .inner-TouchTest{
                .main-Container{
                    max-width: 731px;
                        padding: 70px 0 72px 0;
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-TouchTest {
            .inner-TouchTest{
                .main-Container{
                    max-width: 667px;
                    // margin-left: 95px;
                    padding: 70px 0 72px 0;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-TouchTest {
            display: block !important;
            .inner-TouchTest{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 100px 0 137px 0;
                    .btn{
                        float: left;
                    }
                  
                   
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-TouchTest {
            display: block !important;
            .inner-TouchTest{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 100px 0 110px 0;
                    // margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                 
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-TouchTest {
            display: block !important;
            .inner-TouchTest{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;;
                display: block !important;;
                .main-Container{
                    width: 452px !important;
                    padding: 80px 0 90px 0;
                    .btn{
                        float: left;
                    }
                   
                }
            }
        }
    }