@import "./../../../assets/scss/variables.scss";
.main-5mmFitting{
    display: inline-flex;
    width: 100%;
    .inner-5mmFitting{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 35px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 0px 0 0px 0;
            width:100%;
            display: table;
            .img-Container{
                padding-bottom: 40px;
                display: table;
            }
            .content-Section{
                padding-bottom: 0px;
            }
            .icon-css{
                  position: absolute;
                    top: 8px;
                    right: 0px;
                }
              .custom-Ul{
                padding-bottom: 40px;
                h5{
                    position: relative;
                    padding-left: 20px;
                    padding-bottom: 8px;
                    .dots-Css {
                        width: 8px;
                        height: 8px;
                        background: $secondaryColor;
                        position: absolute;
                        top: 9px;
                        left: 0;
                        border-radius: 50%;
                    }
                }
                h3{
                    color:$secondaryColor;
                    font-weight: 500 !important;
                }
            }
            // .video-Section {
    //        padding-bottom: 24px;
    // }
           
        }
        .btn{
            margin-bottom: 0px;
        }
}

    }
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-5mmFitting {
            .inner-5mmFitting{
                .main-Container{
                    max-width: 731px;
                    padding: 0;
                    .img-Container{
                        padding-bottom: 35px;
                    }
                    .custom-Ul {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-5mmFitting {
            .inner-5mmFitting{
                .main-Container{
                    max-width: 667px;
                     padding: 0;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                     .img-Container{
                        padding-bottom: 30px;
                    }
                    .custom-Ul {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-5mmFitting {
            display: block !important;
            .inner-5mmFitting{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                     padding: 35px 0 35px 0;
                    .btn{
                        float: left;
                    }
                  
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-5mmFitting {
            display: block !important;
            .inner-5mmFitting{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 32px 0 32px 0;
                    .btn{
                        float: left;
                    }
                
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-5mmFitting {
            display: table !important;
            .inner-5mmFitting{
                width: 100% !important;;
                padding-top: 10px;
                // padding-bottom: 55px !important;;
                display: table !important;
                   .mm-Fitting-Scroll{
                    height: calc(100vh - 70px) !important;
                }
                .main-Container{
                    width: 452px !important;
                        padding: 30px 0 0px 0;
                    // margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                
                }
            }
        }
    }