@import "../../assets/scss/variables.scss";
.Adminheader {
  display: block;
  width: 70%;
  height: 80px;
  background: $whiteColor;
  float: left;
  width: 100%;
  border-left: 1px solid $bgColor;
  display: inline-block;
  position: relative;
  .icon-menu {
    display: none;
    position: absolute;
    top: 31px;
    left: 31px;
    cursor: pointer;
    .menuCss {
    }
  }
  .img-Logo {
    width: 40%;
    float: left;
    display: inline-block;
    padding: 18px 0;
    padding-left: 1%;
    a {
      text-decoration: none !important;
    }
    .page-Title {
      font-size: $fontSize16 !important;
      font-weight: 500 !important;
      margin-bottom: 0 !important;
      color: $secondaryColor;
      padding-left: 55px;
      float: left;
      padding-top: 10px;
    }
    .paddingR {
      color: $closeColor;
      font-size: $fontSize16 !important;
      font-weight: 500 !important;
      margin-bottom: 0 !important;
      padding-left: 55px;
      float: left;
      padding-top: 10px;
    }
    .img-Section {
      width: 110px;
      float: left;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  .main-header {
    float: right;
    display: flex;
    padding: 15px 0;
    padding-right: 32px;
    .notifications {
      display: table;
      float: left;
      padding-top: 14px;
      padding-right: 55px;
      position: relative;
      .icon-css {
        color: $innerColor;
        font-size: $fontSize24;
        cursor: pointer;
      }
      .badge-Count {
        height: 18px;
        // width: 18px;
        border-radius: 50%;
        font-size: $fontSize12;
        background: $primaryColor;
        font-family: $primary-font-family;
        font-weight: 600;
        color: $whiteColor;
        position: absolute;
        right: 45px;
        top: 10px;
      }
    }
    .profile-section {
      display: flex;
      .profile-Img {
        width: 50px;
        height: 50px;
        margin-right: 16px;
        align-items: center;
        align-self: center;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          max-width: 100%;
          object-position: center;
        }
      }

      .profile-Setting {
        display: block;
        padding-top: 6px;
        .dropdowncomponent {
          border-bottom: none;
          width: 128px;
          .dropdownButtom {
            .btn {
              font-weight: 400;
              &:before {
                bottom: 7px;
                right: -4px;
              }
            }
          }
          .dropdown-menu {
            &.show {
              max-width: 128px;
              display: table;
              background: $whiteColor;
              border-radius: 4px;
              border-color: $whiteColor;
              box-shadow: 1px 5px 13px rgba(0, 0, 0, 0.5);
              -webkit-box-shadow: 1px 5px 13px rgba(0, 0, 0, 0.5);
              -moz-box-shadow: 1px 5px 13px rgba(0, 0, 0, 0.5);
              .dropdown-item {
                font-size: $fontSize13 !important;
                font-weight: 400 !important;
                padding: 5px 4px;
              }
              &:before {
                content: "";
                position: absolute;
                border-width: 0 0 0 0px;
                border-style: solid;
                top: -5px;
                right: 52px;
                width: 13px;
                height: 14px;
                margin-bottom: -2px;
                border-top: 1px solid $whiteColor;
                border-left: 1px solid $whiteColor;
                background: #fff;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .Adminheader {
    width: 100%;
    .img-Logo {
      .img-Section {
        img {
          display: none;
        }
      }
      .page-Title {
        padding-left: 20px;
      }
      .paddingR {
        padding-left: 60px !important;
      }
    }
    .main-header {
      .profile-section {
        .profile-Img {
          width: 40px;
          height: 40px;
        }
      }
      .notifications {
        padding-right: 40px;
        .badge-Count {
          right: 34px;
        }
      }
    }
    .icon-menu {
      display: block;
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 679px) {
  .Adminheader {
    .img-Logo {
      width: 50%;
      .paddingR {
        padding-left: 40px !important;
      }
    }
    .main-header {
      width: 45%;
    }
    .icon-menu {
      left: 18px;
    }
  }
}
