@import "./../../../assets/scss/variables.scss";
.main-reportdetail{
    display: inline-flex;
    width: 100%;
    .inner-TestReport5mm{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
         width: 100% !important;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 130px 0 0 0;
            width: 100%;
            // display: table;
            .img-Container{
                padding-bottom: 40px;
                display: table;
            }
            .content-Section{
                padding-bottom: 25px;
            }
           .question-Form{
            display: table;
            width: 100%;
            padding-bottom: 0;
               .row{
                  .textarea-Section{
                       label{
                    display: block;
                    position: relative;
                    font-size: $fontSize16 !important;
                    color:$innerColor;
                    font-weight: 400 !important;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    &.cust-Label{
                        font-size: $fontSize14 !important;
                        color: $labelColor!important;
                        font-weight: 400!important;
                        display: table!important;
                        margin: 0!important;
                        
                    }
                }
                 .textarea {
                    resize: none;
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #d5d5d5;
                    background: transparent;
                    padding: 0;
                    &:focus{
                        outline: none;
                    }
                }
                  }
               }
           }
           .only-Content{
                   display: table;
                padding-top: 0px;
                padding-bottom: 12px;
               .contents{
                   font-size: $fontSize16 ;
                    color:$innerColor;
                    font-weight: 400 !important;
                    margin: 0;
               }
           }
           .retakeTest{

           }
           
        }
        .btn{
            margin-top: 25px;
            margin-bottom: 32px;
        }
    }
.test{
    display: none !important;
}
    }
  
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-TestReport5mm {
            .inner-TestReport5mm{
                .main-Container{
                    max-width: 731px;
                    padding: 60px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-TestReport5mm {
            .inner-TestReport5mm{
                .main-Container{
                    max-width: 667px;
                    // margin-left:15%;
                     padding: 70px 0 0px 0!important;
                  
                    .question-Form{
                       padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-TestReport5mm {
            display: block !important;
            .inner-TestReport5mm{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 40px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-TestReport5mm {
            display: block !important;
            .inner-TestReport5mm{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 40px 0 0px 0;
                  .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-TestReport5mm {
            display: block !important;
            .inner-TestReport5mm{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 0px !important;
                display: table !important;
                .testreport-Scroll{
                    height: calc(100vh - 90px) !important;
                }
                .main-Container{
                    width: 452px !important;
                    padding: 10px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                    .btn{
                        // margin-top: 30px;
                    }
                }
            }
        }
    }