@import "../../assets/scss/variables.scss";
.timer-Bar {
	// position: relative;
	width: 448px;
	.timerRadius {
		.CircularProgressbar {
			width: 382px;
			height: 382px;
			margin: 0 auto;
			display: block;
		}
		.click-Button {
			position: absolute;
			background: $secondaryColor;
			top: 13px;
			text-align: center;
			left: 0px;
			right: 0;
			display: block;
			margin: 0 auto;
			height: 356px;
			width: 356px;
			border-radius: 50%;
			// box-shadow: 0 2px 8px 22px rgba(0, 0, 0, 0.02);
			// -moz-box-shadow: 0 2px 8px 22px rgba(0, 0, 0, 0.02);
			// -webkit-box-shadow: 0 2px 8px 22px rgba(0, 0, 0, 0.02);
			&.disabled {
				pointer-events: none;
				cursor: default;
				text-decoration: none;
				color: black;
			}
			.text-Section {
				// display: table;
				height: 100%;
				width: 100%;
				border-radius: 50%;
				-webkit-box-shadow: -2px 2px 19px 5px rgba(89, 4, 89, 0.02);
				-moz-box-shadow: -2px 2px 19px 5px rgba(89, 4, 89, 0.32);
				box-shadow: -2px 2px 19px 5px rgba(89, 4, 89, 0.32);
				position: relative;
				h2 {
					color: $whiteColor;
					font-weight: 400 !important;
				}
				.main-title {
					padding-top: 30px;
					padding-bottom: 25px;
					&:before {
						content: 'Click';
					}
				}
				.inner-title {
					padding-top: 30px;
					padding-bottom: 15px;
				}
				.icon-css {
					color: $whiteColor;
					font-size: 90px;
				}
				.content-Icon {
					&:before {
						content: "\e90a";
					}
				}
				.time-Bar {
					color: $whiteColor;
					font-size: $fontSize60;
				}
				.text-wrap {
					position: absolute;
					left: 162px;
					z-index: 99;
					top: -11px;
					.badge {
						position: relative;
						width: 400px;
						border-radius: 50%;
						transform: rotate(0deg);
						p {
							margin: 0;
							span {
								font: 26px Monaco, MonoSpace;
								height: 200px;
								position: absolute;
								width: 20px;
								left: 0;
								top: 0;
								transform-origin: bottom center;
								color: #ffffff;
								font-size: 13px !important;
							}
						}
						.char1 {
							transform: rotate(3deg);
						}
						.char2 {
							transform: rotate(6deg);
						}
						.char3 {
							transform: rotate(9deg);
						}
						.char4 {
							transform: rotate(12deg);
						}
						.char5 {
							transform: rotate(15deg);
						}
						.char6 {
							transform: rotate(18deg);
						}
						.char7 {
							transform: rotate(21deg);
						}
						.char8 {
							transform: rotate(24deg);
						}
						.char9 {
							transform: rotate(27deg);
						}
					}
				}
			}
		}
	}
	.touch-Detail {
		display: table;
		margin: 0 auto;
		.no-Touches {
			font-size: 72px;
			color: $secondaryColor;
			font-weight: 300 !important;
			padding-top: 40px;
			padding-bottom: 15px;
			display: block;
		}
		.no-text {
			font-weight: 400 !important;
			//  padding-bottom: 30px;
		}
	}
	.btn {
		position: relative;
		margin-top: 30px;
		.icon-css {
			color: $whiteColor;
			font-size: $fontSize16;
			padding-left: 9px;
			padding-top: 0;
			display: inline-block;
		}
	}
	.sec-Img {
		width: 383px;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0;
		display: block;
		margin: 0 auto;
	}
	.timerBtn {
		display: flex;
		.submitBtn {
			&.btn {
				display: inline-flex;
				justify-content: center;
				margin: 30px 12px 0;
				align-items: center;
			}
			&:first-child {
				background: white;
				font-weight: bold;
				line-height: 25px;
				color: #590459;
				box-shadow: 0px 0px 15px 0px rgba(215, 215, 215, 0.61);
				&:hover {
					background: white;
					color: #590459;
					box-shadow: 0px 0px 15px 0px rgba(215, 215, 215, 0.61);
				}
			}
		}
	}
}

// @media only screen and (min-width:993px) and (max-width:1024px) {
//     .timer-Bar {
//         width: 280px;
//  .CircularProgressbar {
//         width: 280px;
//         height: 280px;
//     }
//         .sec-Img {
//             width: 270px;
//             top: 8px;
//             left: 0px;
//             right: 0;
//         }
//         .click-Button {
//             width: 244px;
//             height: 244px;
//             left: 0px;
//             right: 0;
//             .text-Section {
//                 .main-title {
//                     padding-top: 25px;
//                     padding-bottom: 10px;
//                     &:before {
//                         display: none;
//                     }
//                     &:after {
//                         content: 'Tab';
//                     }
//                 }
//                 .content-Icon {
//                     font-size: 70px;
//                     &:before {
//                         display: none;
//                     }
//                     &:after {
//                         content: "\e90b";
//                     }
//                 }
//                 .inner-title {
//                     padding-top: 12px;
//                     padding-bottom: 0;
//                 }
//                 .time-Bar {
//                     font-size: 40px;
//                 }
//             }
//         }
//         .touch-Detail {
//             .no-Touches {
//                 padding-top: 0;
//                 padding-bottom: 0;
//             }
//         }
//     }
// }
// @media only screen and (min-width:768px) and (max-width:992px) {
//     .timer-Bar {
//         width: 280px;
//         .CircularProgressbar {
//         width: 280px;
//         height: 280px;
//     }
//         .sec-Img {
//             width: 270px;
//             top: 8px;
//             left:0;
//             right: 0;
//         }
//         .click-Button {
//             width: 244px;
//             height: 244px;
//             left: 0px;
//             right: 0;
//             .text-Section {
//                 .main-title {
//                     padding-top: 25px;
//                     padding-bottom: 10px;
//                     &:before {
//                         display: none;
//                     }
//                     &:after {
//                         content: 'Tab';
//                     }
//                 }
//                 .content-Icon {
//                     font-size: 70px;
//                     &:before {
//                         display: none;
//                     }
//                     &:after {
//                         content: "\e90b";
//                     }
//                 }
//                 .inner-title {
//                     padding-top: 12px;
//                     padding-bottom: 0;
//                 }
//                 .time-Bar {
//                     font-size: 40px;
//                 }
//             }
//         }
//         .touch-Detail {
//             .no-Touches {
//                 padding-top: 0;
//                 padding-bottom: 0;
//             }
//         }
//     }
// }
// @media only screen and (min-width:500px) and (max-width:767px) {
//     .timer-Bar {
//         width: 317px;
//     .CircularProgressbar {
//         width: 290px;
//         height: 290px;
//     }
//         .sec-Img {
//             width: 280px;
//             top: 8px;
//             left: 0px;
//             right: 0;
//         }
//         .click-Button {
//             width: 254px;
//             height: 254px;
//             left: 0;
//             right: 0;
//             top: 18px;
//             display: block;
//             margin: 0 auto;
//             .text-Section {
//                 .main-title {
//                     padding-top: 25px;
//                     padding-bottom: 10px;
//                     &:before {
//                         display: none;
//                     }
//                     &:after {
//                         content: 'Tab';
//                     }
//                 }
//                 .content-Icon {
//                     font-size: 70px;
//                     &:before {
//                         display: none;
//                     }
//                     &:after {
//                         content: "\e90b";
//                     }
//                 }
//                 .inner-title {
//                     padding-top: 12px;
//                     padding-bottom: 0;
//                 }
//                 .time-Bar {
//                     font-size: 40px;
//                 }
//             }
//         }
//         .touch-Detail {
//             .no-Touches {
//                 padding-top: 0;
//                 padding-bottom: 0;
//             }
//         }
//     }
// }