@import "./../../../assets/scss/variables.scss";
.main-SetReminder{
     width: 100%;
    display: inline-block;
      background: #F6F7FB;
          height: 100vh;
    .inner-SetReminder{
         height: calc(100vh - 80px);
        display: inline-block;
        width: calc(100% - 312px);  
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 300px 0 0px 0;
            width:100%;
            display: table;
            .img-Container{
                width: 140px;
                height: 126px;
                padding-bottom: 40px;
                display: table;
            }
             .question-Form{
            display: table;
            width: 100%;
            padding-bottom: 40px;
            .row{
                 .custom-Datepicker{
                       .react-datepicker-wrapper{
                        width: 100%;
                        padding-top: 12px;
                        .react-datepicker__input-container{
                            input{
                                width: 100%;
                                border: none;
                                background: transparent;
                                border-bottom:1px solid $bordeColor ;
                                font-size: $fontSize16;
                                color: $innerColor;
                                font-weight: 500;
                                font-family: $primary-font-family;
                                &:focus{
                                    outline: none;
                                }
                            }
                        }
                       }
                       .react-datepicker-popper{
                           .react-datepicker{
                            font-family: $primary-font-family ;
                            border: none;
                            width: 250px;
                               .react-datepicker__month-container{
                                     width: 250px;
                                   .react-datepicker__header{
                                    box-shadow: 0 2px 8px 2px rgba(0,0,0,.05);
                                    -webkit-box-shadow: 0 2px 8px 2px rgba(0,0,0,.05);
                                    -moz-box-shadow:  0 2px 8px 2px rgba(0,0,0,.05);
                                    border: none;
                                    background: #ffffff;
                                    border-radius: 0;
                                    font-family: $primary-font-family ;
                                    .react-datepicker__current-month{
                                        display: none;
                                    }
                                 .react-datepicker__day-name {
                                     font-family: $primary-font-family ;
                                     font-weight: 400;
                                     font-size: 12px;
                                 }
                                   }
                                   .react-datepicker__month{
                                    box-shadow: 0 4px 8px 2px rgba(0,0,0,.08);
                                     -webkit-box-shadow:0 4px 8px 2px rgba(0,0,0,.08);
                                     -moz-box-shadow:0 4px 8px 2px rgba(0,0,0,.08);
                                    font-family: $primary-font-family  !important;
                                    margin: 0;
                                    padding: 10px 0 0;
                                    .react-datepicker__day--today{
                                        background: $primaryColor;
                                        border-radius: 16px;
                                        color: #fff;
                                    }
                                    .react-datepicker__day{
                                        font-family: $primary-font-family ;
                                        &:hover{
                                            background: $primaryColor;
                                        border-radius: 16px;
                                        color: #fff;
                                        }
                                    }
                                    .react-datepicker__day--selected{
                                        background: $primaryColor;
                                        border-radius: 16px;
                                        color: #fff;
                                    }
                                   }
                               }
                               .react-datepicker__triangle{
                                   display: none;
                               }
                           }
                       }
                       .react-datepicker-popper[data-placement^="bottom"] {
                        margin-top: 0;
                    }
                   }
                   .dropdowncomponent{
                       .dropdown-menu{
                           height: 300px;
                           overflow-y: scroll;
                       }
                   }
            }
               
           }
            
           
        }
        .btn{
            margin-bottom: 0px;
        }
}

    }
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-SetReminder {
            .inner-SetReminder{
                .main-Container{
                    max-width: 731px;
                    // padding: 120px 0 130px 0;
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-SetReminder {
            .inner-SetReminder{
                .main-Container{
                    max-width: 667px;
                    // margin-left: 95px;
                    // padding: 120px 0 130px 0;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-SetReminder {
            display: block !important;
            .inner-SetReminder{
                width: 100%;
                // padding-top: 15px;
                // padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    // padding: 100px 0 137px 0;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 0;
                        width: 60%;
                        float: left;
                        padding-left: 61px;
                    }
                    .checkbox-Section{
                        margin-bottom: 25px !important;
                    }
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-SetReminder {
            display: block !important;
            .inner-SetReminder{
                width: 100% !important;;
                // padding-top: 15px;
                // padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    // padding: 100px 0 110px 0;
                    // margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 25px;
                        width: 100%;
                        float: left;
                        padding-left: 0;
                    }
                    .checkbox-Section{
                        margin-bottom: 25px !important;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-SetReminder {
            display: block !important;
            .inner-SetReminder{
                width: 100% !important;;
                // padding-top: 15px;
                // padding-bottom: 05px !important;;
                display: table!important;;
                .main-Container{
                    width: 452px !important;
                    // padding: 80px 0 90px 0;
                    // margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 25px;
                        width: 100%;
                        float: left;
                        padding-left: 0;
                    }
                }
            }
        }
    }