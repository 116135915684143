@import "./../../../assets/scss/variables.scss";
.main-PhotoGuide{
    display: inline-flex;
    width: 100%;
    
    .inner-PhotoGuide{
        // width: calc(100% - 312px);
        // width: -moz-calc(100% - 312px);
        // float: left;
        // display: block;
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            width:100%;
           height:100%;
        //    display: table;
           .img-Container {
            display: table;
            padding-bottom: 40px;
        }
            .content-Section{
                padding-bottom: 18px;
                display: block;
               
            }
            .custom-Ul{
                padding-bottom: 40px;
                h5{
                    position: relative;
                    padding-left: 20px;
                    padding-bottom: 8px;
                    .dots-Css {
                        width: 8px;
                        height: 8px;
                        background: $secondaryColor;
                        position: absolute;
                        top: 9px;
                        left: 0;
                        border-radius: 50%;
                    }
                }
            }
            .upgrade-Section {
                padding-top: 32px;
                padding-bottom: 20px;
                h5{
                    &:last-child{
                        padding-bottom: 0px;
                    }
                    .icon-css{
                        &:before{
                            position: absolute;
                            top: 5px;
                            right: -20px;
                        }
                    }
                }
            }
           
        }
}
    }
   
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .main-PhotoGuide{
            height: 100%;
            height: 100vh;
            .inner-PhotoGuide{
            height: 100%;
            height: 100vh;
          }
        }
      }

    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-PhotoGuide {
            .inner-PhotoGuide{
                .main-Container{
                    max-width: 667px;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-PhotoGuide {
            display: block !important;
            .inner-PhotoGuide{
                width: 100% !important;
                padding-top: 15px;
                padding-top: 15px;
                padding-bottom: 55px;
                display: block;
                .main-Container{
                    max-width: 667px;
                    padding: 20px 0 20px 0;
                    .upgrade-Section{
                        padding-bottom: 75px;
                        h5{
                            // &:last-child{
                            //     padding-bottom: 40px;
                            // }
                        }
                       
                    }
                    .custom-Ul{
                        .col-sm-6{
                            &:last-child{
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-PhotoGuide {
            display: block !important;
            .inner-PhotoGuide{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 55px;
                display: block;
                .main-Container{
                    max-width: 625px;
                    padding: 20px 0 20px 0;
                    .upgrade-Section{
                        // padding-bottom: 45px;
                        h5{
                            // &:last-child{
                            //     padding-bottom: 40px;
                            // }
                        }
                       
                    }
                   
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-PhotoGuide {
            display: block !important;
            .inner-PhotoGuide{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 55px;
                display: block;
                .photo-Scroll{
                    height: calc(100vh - 180px) !important;
                }
                .main-Container{
                    max-width: 442px;
                    padding: 20px 0 20px 0;
                    
                }
            }
        }
    }