@import "./../../../assets/scss/variables.scss";
.main-QuestionPage {
  display: inline-flex;
  width: 100%;
  .questionPage {
    display: inline-flex;
    width: 100%;
    height: 100%;
    height: 100vh;
    padding-top: 40px;
    background: $bgColor;
    // overflow: hidden;
    //  overflow-y: scroll;
    width: 100% !important;
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 45px 0 0 0;
      width: 100%;
      // display: table;
      .img-Container {
        width: 140px;
        height: 126px;
        padding-bottom: 40px;
        display: table;
      }
      .content-Section {
        padding-bottom: 40px;
      }
      .question-Form {
        display: table;
        width: 100%;
        padding-bottom: 40px;
        .row {
          .antiYesfield {
            .textFiled {
              label {
                visibility: hidden;
              }
              #formBasicEmail {
                margin-top: 12px;
              }
            }
          }
          .custom-Datepicker {
            .react-datepicker-wrapper {
              width: 100%;
              padding-top: 12px;
              .react-datepicker__input-container {
                input {
                  width: 100%;
                  border: none;
                  background: transparent;
                  border-bottom: 1px solid $bordeColor;
                  font-size: $fontSize16;
                  color: $innerColor;
                  font-weight: 500;
                  font-family: $primary-font-family;
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            .react-datepicker-popper {
              .react-datepicker {
                font-family: $primary-font-family;
                border: none;
                width: 250px;
                .react-datepicker__month-container {
                  width: 250px;
                  .react-datepicker__header {
                    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.05);
                    -webkit-box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.05);
                    -moz-box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.05);
                    border: none;
                    background: #ffffff;
                    border-radius: 0;
                    font-family: $primary-font-family;

                    .react-datepicker__month-read-view--selected-month{
                      font-size: 14px;
                      font-weight: 500;
                    }
                    .react-datepicker__year-read-view--selected-year {
                        font-size: 14px;
                        font-weight: 500;
                      }
                    .react-datepicker__month-read-view--down-arrow{
                        border-width: 5px;
                    }
                    .react-datepicker__month-year-read-view--down-arrow{
                        border-width: 5px;
                    }

                    .react-datepicker__current-month {
                      display: none;
                    }
                    .react-datepicker__day-name {
                      font-family: $primary-font-family;
                      font-weight: 400;
                      font-size: 12px;
                    }
                  }
                  .react-datepicker__month {
                    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
                    -webkit-box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
                    -moz-box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
                    font-family: $primary-font-family !important;
                    margin: 0;
                    padding: 10px 0 0;
                    .react-datepicker__day--today {
                      background: $primaryColor;
                      border-radius: 16px;
                      color: #fff;
                    }
                    .react-datepicker__day {
                      font-family: $primary-font-family;
                      &:hover {
                        background: $primaryColor;
                        border-radius: 16px;
                        color: #fff;
                      }
                    }
                    .react-datepicker__day--selected {
                      background: $primaryColor;
                      border-radius: 16px;
                      color: #fff;
                    }
                  }
                }
                .react-datepicker__triangle {
                  display: none;
                }
              }
            }
            .react-datepicker-popper[data-placement^="bottom"] {
              margin-top: 0;
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-QuestionPage {
    .questionPage {
      .main-Container {
        max-width: 731px;
        padding: 20px 0 0px 0;
        .question-Form {
          padding-bottom: 20px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-QuestionPage {
    .questionPage {
      .main-Container {
        max-width: 667px;
        // margin-left:15%;
        padding: 20px 0 0px 0 !important;
        .content-Section {
          padding-bottom: 15px !important;
        }
        .question-Form {
          padding-bottom: 22px;
        }
      }
    }
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-QuestionPage {
    display: block !important;
    .questionPage {
      width: 100%;
      padding-top: 15px;
      // padding-bottom: 55px!important;
      display: block !important;
      .main-Container {
        max-width: 667px;
        padding: 20px 0 40px 0;

        .btn {
          float: left;
        }

        .checkbox-Section {
          margin-bottom: 25px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-QuestionPage {
    display: block !important;
    .questionPage {
      width: 100% !important;
      padding-top: 15px;
      // padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        width: 625px;
        padding: 10px 0 0px 0;

        .checkbox-Section {
          margin-bottom: 25px !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-QuestionPage {
    display: block !important;
    .questionPage {
      width: 100% !important;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        width: 452px !important;
        padding: 10px 0 20px 0;
      }
    }
  }
}
