@import "./../../../assets/scss/variables.scss";
.main-ReviewPhoto{
    display: inline-flex;
    width: 100%;
    .inner-ReviewPhoto{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
         width: 100% !important;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 45px 0 73px 0;
            width: 100%;
            // display: table;
            .img-Container{
                width: 140px;
                height: 126px;
                padding-bottom: 40px;
                display: table;
            }
            .main-Img-Section{
                padding: 0;
                margin: 0 0 30px;
                display: flex;

                .preview-Section{
                    display: table;
                    img{
                        width: 305px;
                        height: 305px;
                        max-width:100%;
                        object-fit: cover;
                    }
                    .link-Section{
                        display: table;
                        width: 100%;
                        padding-top: 10px;
                        .innerTitle{
                            font-size: $fontSize20;
                        }
                        
                    }
                }
                
            }
           
         
           
        }
        .btn{
            margin-bottom: 0px;
            margin-top: 40px;
        }
}

    }
  
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-ReviewPhoto {
            .inner-ReviewPhoto{
                .main-Container{
                    max-width: 731px;
                    padding: 20px 0 40px 0;
                    
                  
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-ReviewPhoto {
            .inner-ReviewPhoto{
                .main-Container{
                    max-width: 667px;
                    padding: 20px 0 40px 0!important;
                    
                   
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-ReviewPhoto {
            display: block !important;
            .inner-ReviewPhoto{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 20px 0 40px 0;
                    
                    .btn{
                        float: left;
                    }
                    
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-ReviewPhoto {
            display: block !important;
            .inner-ReviewPhoto{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 10px 0 20px 0;
                    
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-ReviewPhoto {
            display: block !important;
            .inner-ReviewPhoto{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;;
                display: block !important;
                  .review-Scroll{
                    height: calc(100vh - 180px) !important;
                }
                .main-Container{
                    width: 452px !important;
                    padding: 10px 0 20px 0;
                    
                    
                }
            }
        }
    }