@import "./../../../assets/scss/variables.scss";

.main-AdminDashboard {
    width: 100%;
    display: inline-block;
    background: $bgColor;
    height: 100vh;
    position: relative;
    .inner-AdminDashboard {
        display: inline-block;
        width: 100%;
        height: 100vh;
            position: relative;
        .home-Scroll {
            height: calc(100vh - 90px) !important;
            .main-Container {
                // display: table;
                width: 100%;
                // height: 100vh;
                padding: 24px 18px 0px 18px;             
                .users-Details {
                    width: 100%;
                    margin-bottom: 24px;
                    display: flex;
                    .user-Ul {
                        width: 80%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        float: left;
                        .user-Li {
                            width: 305px;
                            float: left;
                            height: 110px;
                              display: -webkit-box;
                                display: -moz-box;
                                display: -ms-flexbox;
                                display: -webkit-flex;
                            background-image: url('../../../assets/images/userbg.png');
                            background-size: cover;
                            -webkit-background-size: cover;
                             -moz-background-size: cover;
                             -o-background-size: cover;  
                            background-position: center;
                            background-size: 100% 100%;
                            background-repeat:no-repeat ;

                            margin-right: 15px;
                            &:nth-child(2) {
                                background-image: url('../../../assets/images/activebg.png');
                            }
                            &:nth-child(3) {
                                background-image: url('../../../assets/images/userrecent.png');
                            }
                            .user-Listing {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                .inner-Listing {
                                     display: -webkit-box;
                                display: -moz-box;
                                display: -ms-flexbox;
                                display: -webkit-flex;
                                display: flex;
                                    align-items: center;
                                    padding: 0 15px;
                                    width: 100%;
                                    .img-Section {
                                        width: 40%;
                                        float: left;
                                        padding-left: 10px;
                                        .iconF {
                                            font-size: 70px;
                                            &.whiteC {
                                                color: $whiteColor;
                                            }
                                            &.secondaryC {
                                                color: $secondaryColor;
                                                font-size: 50px;
                                            }
                                        }
                                    }
                                    .content-Section {
                                        width: 60%;
                                        float: left;
                                        .innerTitle {
                                            margin-bottom: 5px;
                                            color: $whiteColor;
                                            &.greyC {
                                                color: $labelColor;
                                            }
                                        }
                                        .user-No {
                                            color: $whiteColor;
                                            &.secondaryC {
                                                color: $secondaryColor;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                            .searchSection{
                    padding-bottom: 0px;
                    width: 20%;
                        display: flex;
    align-items: center
                }
                .user-Dashboard {
                    display: table;
                    width: 100%;
                    h2 {
                        padding-bottom: 8px;
                    }
                    .grid-Section{
                        position: relative;
                    }
                    .noData{
                        position: absolute;
                        top: 200px;
                        left: 50%;
                        p{
                            font-size: 18px;
                            margin: 0;
                            color:$labelColor;
                        }
                    }
                    .grid-Ul {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        justify-content: center;
                        .grid-li {
                            // width: 300px;
                            width: 16%;
                            float: left;
                            margin-right: 15px;
                            margin-bottom: 15px;
                            background: $whiteColor;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
                            -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
                            -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
                            border-radius: 4px;
                            border: 1px solid $lightBorder;
                            padding: 12px;
                            float: left;
                            word-break: break-all;
                            cursor: pointer;
                            &:nth-of-type(6n+6) {
                                margin-right: 0px;
                            }
                            .grid-Details {
                                display: block;
                                text-align: center;
                                width: 100%;
                                .img-Profile {
                                    width: 60px;
                                    height: 60px;
                                    display: table;
                                    margin: 0 auto;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 50%;
                                    }
                                }
                                .user-Details {
                                    margin-top: 16px;
                                    display: block;
                                    .innerTitle {
                                        margin-bottom: 8px;
                                        &.lightG {
                                            color: $lightGrey;
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar { 
    display: block; 
}
@media only screen and (min-width:1669px) and (max-width:1910px) {
    .main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                                width: 15.9%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:1279px) and (max-width:1670px) {
    .main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                                width: 19%;
                                &:nth-of-type(5n+5) {
                                    margin-right: 0px;
                                }
                                &:nth-of-type(6n+6) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                                width: 19%;
                                &:nth-of-type(5n+5) {
                                    margin-right: 0px;
                                }
                                &:nth-of-type(6n+6) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
 .main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
                    .users-Details{
                        flex-direction: column;
                        .user-Ul{
                              width: 100%;
                          
                        }
                        .searchSection{
                            width: 40%;
                            padding-top: 20px;
                        }
                    }
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                                width: 23.8%;
                                 &:nth-of-type(4n+4) {
                                    margin-right: 0px !important;
                                }
                                &:nth-of-type(5n+5) {
                                    margin-right: 15px;
                                }
                                &:nth-of-type(6n+6) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
                    .users-Details{
                        flex-direction: column;
                        .user-Ul{
                              width: 100%;
                          
                        }
                        .searchSection{
                            width: 40%;
                            padding-top: 20px;
                        }
                    }
                  .users-Details{
                     .user-Ul {
                       .user-Li{
                         &:nth-child(3){
                           margin-right: 0;
                         }
                       }
                     }
                  }
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                               width: 23.8%;
                                 &:nth-of-type(4n+4) {
                                    margin-right: 0px !important;
                                }
                                &:nth-of-type(5n+5) {
                                    margin-right: 15px;
                                }
                                &:nth-of-type(6n+6) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
                    .users-Details{
                        flex-direction: column;
                        .user-Ul{
                              width: 100%;
                          
                        }
                        .searchSection{
                            width: 40%;
                            padding-top: 20px;
                        }
                    }
                  .users-Details{
                     .user-Ul {
                       .user-Li{
                       width: 233px;
                       border-radius: 15px;                   
                       &:nth-child(3) {
                         margin-right: 0;
                       }
                       .user-Listing{
                         .inner-Listing {
                           .img-Section{
                                 padding-left: 0;
                                 .iconF{
                                   font-size: 60px;
                                 }
                           }
                         }
                       }
                       }
                     }
                  }
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                               width: 31.9%;
                                &:nth-of-type(3n+3) {
                                    margin-right: 0px !important ;
                                }
                                 &:nth-of-type(4n+4) {
                                    margin-right: 15px ;
                                }
                                &:nth-of-type(5n+5) {
                                    margin-right: 15px;
                                }
                                &:nth-of-type(6n+6) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
.main-AdminDashboard {
        .inner-AdminDashboard {
            .home-Scroll {
                .main-Container {
               padding: 0 18px ;
                    .users-Details{
                        flex-direction: column;
                        .user-Ul{
                              width: 100%;  
                              .user-Li{
                                  margin-top: 24px;
                              }                        
                        }
                        .searchSection{
                            width: 75%;
                            padding-top: 20px;
                        }
                    }
                   .users-Details{
                     .user-Ul {
                       .user-Li{
                       width: 300px;
                       border-radius: 15px;
                       &:nth-child(3) {
                         margin-right: 0;
                         margin-top: 24px;
                       }
                       .user-Listing{
                         .inner-Listing {
                           .img-Section{
                                 padding-left: 0;
                                 .iconF{
                                   font-size: 60px;
                                 }
                           }
                         }
                       }
                       }
                     }
                  }
                    .user-Dashboard {
                        .grid-Ul {
                            .grid-li {
                               width: 48%;
                               &:nth-of-type(2n+2) {
                                    margin-right: 0px !important ;
                                }
                                &:nth-of-type(3n+3) {
                                    margin-right: 15px;
                                }
                                 &:nth-of-type(4n+4) {
                                    margin-right: 15px ;
                                }
                                &:nth-of-type(5n+5) {
                                    margin-right: 15px;
                                }
                                &:nth-of-type(6n+6) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.disable{
    pointer-events:none; //This makes it not clickable
    opacity:0.6;         //This grays it out to look disabled
}
.deviceUsersTitle {
    line-height: 43px;
    width: 100%;
    float: left;
    margin-bottom: 10px !important;
}
.userStatusBanner{float: right;}
.userStatusBanner img{ width: 450px;}
