@import "../../assets/scss/variables.scss";

.searchboxMain {
	padding: 0;
	width: 100%;
	display: inline-block;
	position: relative;
	.searchInput {
		width: 100%;
		border: 1px solid transparent;
		background: $whiteColor;
		padding: 7px 16px;
		border-radius: 8px;
		font-family: $primary-font-family;
		-webkit-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
		-moz-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
		box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		color:$secondaryColor;
		&::-webkit-input-placeholder {
			color: $placeholder;
		}
		&::-moz-placeholder {
			color: $placeholder;
		}
		&:-ms-input-placeholder {
			color: $placeholder;
		}
		&:-moz-placeholder {
			color: $placeholder;
		}
		&:focus {
			outline: none;
		}
	}
	.close-icon {
		border: 1px solid transparent;
		background-color: transparent;
		display: inline-block;
		vertical-align: middle;
		outline: 0;
		position: absolute;
		right: 16px;
		top: 0px;
		line-height: 40px;
		cursor: pointer;
		&:after {
			content: "\e902";
			  font-family: 'oralift' !important;
			display: block;
			position: absolute;
			z-index: 1;
			right: 0px;
			top: 0;
			margin: auto;
			padding: 0px;
			text-align: center;
			color: $bordeColor;
			font-weight: normal;
			font-size: $fontSize14;
			cursor: pointer;
			line-height: 38px;
		}
	}
	.searchInput:not(:valid)~.close-icon {
		&:after {
			display: none;
		}
	}
}