@import "./../../../assets/scss/variables.scss";
.main-Loginpage {
  display: inline-flex;
  width: 100%;
  height: 100vh;
  background-image: url("../../../assets/images/bglogin1.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .inner-Loginpage {
    height: 500px;
    width: 400px;
    background-color: $whiteColor;
    border-radius: 5px;
    position: absolute;
    left: 190px;
    top: 0px;
    bottom: 0;
    margin: auto;
    padding: 40px 28px 40px 28px;

    .loginTextContent {
      background: none !important;
      border: none !important;
      border-bottom: 1px solid $bordeColor !important;
      border-radius: 0;
      padding: 0;
      font-size: $fontSize16;
      color: $innerColor;
      font-weight: 500;
      font-family: $primary-font-family;
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      line-height: 1.5;
      outline: none;
    }
    .logoSection{
  width: 180px;
  margin-bottom: 16px;
        height: 65px;
      .logo {
        width: 100%;
      
    }
    }
    .mBottom65 {
      margin-bottom: 40px !important;
    }
    .submitBtn {
      margin-top: 30px !important;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-Loginpage {
    display: block !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Loginpage {
    display: block !important;
    .inner-Loginpage {
      height: 475px;
      width: 350px;
      left: 70px;
      top: 200px;
      padding: 30px 28px 40px 28px;

      .mBottom65 {
        margin-bottom: 20px !important;
      }
      .submitBtn {
        margin-top: 35px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Loginpage {
    display: block !important;
    .inner-Loginpage {
      height: 475px;
      width: 350px;
      left: 70px;
      top: 200px;
      padding: 30px 28px 40px 28px;

      .mBottom65 {
        margin-bottom: 20px !important;
      }
      .submitBtn {
        margin-top: 10px !important;
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Loginpage {
    display: block !important;
    background-color: #b6bcbd;
    background-image: url();
    .inner-Loginpage {
      height: 475px;
      width: 350px;
      left: 0px;
      top: 0px;
      bottom: 0;
      right: 0;
      padding: 30px 28px 40px 28px;

      .mBottom65 {
        margin-bottom: 20px !important;
      }
      .submitBtn {
        margin-top: 10px !important;
      }
    }
  }
}
