@import "./../../../assets/scss/variables.scss";

.main-Instructions {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-Instructions {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 40px 0 10px 0;
      width: 100%;
      display: table;

      .more_info_main {
        text-align: right;
        display: block;
        padding: 7px 0;
        .tooltip_main{
          display: inline-block;
          .divclass{
            &.show {
              position: fixed;
              width: calc(100% - 312px);
              height: calc(100vh - 80px);
              background: #f3f3f314;
              bottom: 0;
            }
          }
          .tool-Tips {
            
            &.show{
              a {
                span{
                  display: block !important; 
                  text-align: left;
                 }
            }
            }
            a {
                span{
                  display: none !important; 
                 }
            }
            a.tip {
              width: 30px;
              height: 30px;
             
              &.icon-info {
                &:before {
                  padding: 4px 6px;
                }
              }
            }

          }
        }
        


      }

      .moreinfotitle {
        display: inline-block;
        font-size: 16px;
        position: relative;
        top: 1px;
      }

      // .tooltip_main {
      //   display: inline-block;
      //   .tip {
      //     width: 24px;
      //     height: 24px;
      //   }

      //   .icon-info {
      //     &:before {
      //       font-size: 16px !important; 
      //     }
      //   }
      // }
      
      .image-Section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .width50 {
          width: 49%;
          .img-Container {
            width: 100%;
            display: block;
            img {
              width: 100%;
            }
          }
          .main-Section {
            .content-Section {
              
            }
          }
        }
      }
      .aLink {
        color: $blueColor !important;
        font-size: 14px !important;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-Instructions {
    .inner-Instructions {
      .main-Container {
        max-width: 731px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-Instructions {
    .inner-Instructions {
      .main-Container {
        max-width: 667px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Instructions {
    display: block !important;
    .inner-Instructions {
      width: 100%;
      display: block !important;
      .main-Container {
        max-width: 667px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Instructions {
    display: block !important;

    .inner-Instructions {
      width: 100% !important;
      display: block !important;

      .main-Container {
        width: 625px;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Instructions {
    display: block !important;
    .inner-Instructions {
      width: 100% !important;
      display: block !important;

      .main-Container {
        width: 452px !important;
        .main-Section {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          .content-Section {
            display: block;
            padding: 0.5em 0.5em 40px;
            width: 100%;
            .minHeight{
                min-height: 63px;
              }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main-Instructions {
    display: block !important;
    .inner-Instructions {
      width: 100% !important;
      display: block !important;
      padding: 0 15px;

      .main-Container {
        .main-Section {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          .content-Section {
            display: block;
            padding: 0.5em 0.5em 40px;
            width: 100%;
          }
        }
      }
    }
  }
}
