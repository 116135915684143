body {
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
}
.container {
  width: 1170px;
  max-width: 100%;
  margin: auto;
}
.container h1 {
  margin: 20px auto;
  font-size: 1.8rem;
  line-height: 30px;
}
.container p {
  line-height: 2rem;
  font-size: 1.4rem;
  margin-top: 15px;
}

/* declare a 7 column grid on the table */
#calendar {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

#calendar tr,
#calendar tbody {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}

caption {
  text-align: center;
  grid-column: 1 / -1;
  font-size: 130%;
  font-weight: bold;
  padding: 10px 0;
  text-align: center !important;
}

#calendar a {
  color: #590459;
  text-decoration: none;
}

#calendar td,
#calendar th {
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
}

#calendar .weekdays {
  background: #590459;
}

caption {
  padding-top: 0 !important;
  padding-bottom: 5px !important;
  margin-top: -10px;
}

#calendar .weekdays th {
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
  /* border: none !important; */
  padding: 5px 6px;
  color: #fff;
  font-size: 13px;
  border: 1px solid #fff;
}

#calendar td {
  min-height: 118px;
  /* display: flex; */
  flex-direction: column;
  background: #fff;
}
.titlesubb {
  font-size: 10px;
  font-weight: 600;
  color: #999;
  display: none;
}
.datesubb{
  font-size: 18px;
  font-weight: 1000;  
  color : #590059;
  display: flex;
  justify-content: flex-end;
  position: absolute;
    right: 4px;
    top: 3px;
    font-weight: 400;
}
.boxwith_times {
  display: block;
  width: 100%;
  min-height: 58px;
  width: 100%;
  padding-left: 0px;
  background-color: #c1eeb4;
}
.boxwith_times_no_data {
  display: block;
  width: 100%;
  min-height: 58px;
  width: 100%;
  padding-left: 0px;
}
.footer-text {
  color: #590059;
  font-size: 17px !important;
  font-weight: normal;
  margin-top: 5px !important;
}

.footer-box {
  color: #590059;
  font-size: 18px;
  font-weight: 600;
  border: 3px solid #590059;
  padding: 7px 20px;
  display: inline-block;
}

#calendar .date {
  padding-right: 4px;
  font-size: 18px;
}
.firstbx {
  margin-top: -29px;
}
.firstbx .timeBox {
  padding-bottom: 6px;
}
.firstbx.boxwith_times {
  position: relative;
  border-bottom: 4px solid #579e42;
}
.firstbx.boxwith_times:after {
  content: "";
  height: 1px;
  left: 0;
  right: 0;
  position: absolute;
  bottom: -1px;
  background: #ddd;
}

#calendar .days li:hover {
  background: #d3d3d3;
}
.day {
  font-size: 10px;
}
#calendar .date {
  text-align: right;
  background: transparent;
  color: #590459;
  width: 20px;
  border-radius: 50%;
  flex: 0 0 auto;
  align-self: flex-end;
  width: 100%;
  box-sizing: border-box;
}

#calendar .event {
  flex: 0 0 auto;
  font-size: 13px;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
  line-height: 14px;
  background: #590459;
  border: 1px solid #590459;
  color: #fff;
  text-decoration: none;
}

#calendar .event-desc {
  color: #666;
  margin: 3px 0 7px 0;
  text-decoration: none;
}

#calendar .other-month {
  background: #f5f5f5;
}
#calendar .other-month .date {
  color: #999 !important;
}
#calendar .floatRight {
  float: right;
}
#calendar img {
  vertical-align: middle;
}
.touchesBox {
  text-align: center;

  font-size: 16px;
  padding: 1px;
  font-weight: bold;
  color: #000000;

  flex: 0 0 auto;
  display: inline-block;
  align-self: baseline;
  line-height: 18px;
  margin-right: 4px;
}

.legendsTable {
  margin-top: 10px;
  border-collapse: collapse;
}
.legendsTable tr td {
  padding: 5px;
  font-size: 11px;
  vertical-align: top;
  border: 1px solid #f1f1f1;
}
.legendsTable tr th {
  padding: 5px;
  width: 80px;
  border: 1px solid #f1f1f1;
  vertical-align: middle;
}
.deviceTime3mm {
  background: #5d045d;
  color: #fff;
}
.deviceTime5mm {
  background: #e16da9;
  color: #000000;
}
.noOfTouches {
  background: #ffff00;
  color: #000000;
}
.signOfOverdues {
  background: #c00000;
  color: #fff;
}
.Photographs {
  background: #000000;
  color: #fff;
}
.manageHalf {
  display: flex;
  font-size: 13px;
  margin-bottom: 0px;
}
.manageHalf .col-half {
  margin: 0px;
  font-size: 10px;
  text-align: center;
  width: 50%;
  float: left;
  padding: 1px 5px;
}
.timeBox {
  border: 0;
  margin-bottom: 0;
  padding: 6px;
}
#calendar .other-month + .other-month .titlesubb {
  visibility: hidden;
}

.headerhome .main-header {
  padding: 5px 15px;
}
.headerhome {
  height: 62px;
}
.main-HomeProgress .inner-HomeProgress {
  height: calc(100vh - 62px);
}
.main-HomeProgress .inner-HomeProgress .main-Container {
  padding: 15px 0 10px 0;
  max-width: 945px;
}

.table-control-nav {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pre_btn {
  border: solid 1px #b9aab9;
  padding: 10px;
  height: 35px;
  text-align: center;
  position: relative;
  width: 35px;
  border-radius: 6px;
  background: #fff;
  margin-right: 5px;
}
.tbl-caption {
  color: #590459;
  margin: 0px;
  padding: 0 !important;
}

.pre_btn:after {
  position: absolute;
  content: "";
  border: solid black;
  border-width: 0 3px 3px 0;
  top: 12px;
  left: 15px;
  display: inline-block;

  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.next_btn {
  border: solid 1px #b9aab9;
  padding: 10px;
  height: 35px;
  text-align: center;
  position: relative;
  width: 35px;
  border-radius: 6px;
  background: #fff;
}

.next_btn:after {
  position: absolute;
  content: "";
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  top: 12px;
  left: 11px;
}
/* ============================
	  
					  Mobile Responsiveness
		 ============================*/

@media (max-width: 768px) {
  #calendar .weekdays,
  #calendar .other-month {
    display: none;
  }

  #calendar li {
    height: auto !important;
    border: 1px solid #ededed;
    width: 100%;
    padding: 10px;
    margin-bottom: -1px;
  }

  #calendar,
  #calendar tr,
  #calendar tbody {
    grid-template-columns: 1fr;
  }

  #calendar tr {
    grid-column: 1 / 2;
  }

  #calendar .date {
    align-self: flex-start;
  }
}

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 0%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}


@media screen and (max-width:767px)
{
  .touchesBox{ font-size: 12px;}
  .touchesBox svg{ width:16px;}
}

@media screen and (max-width:575px)
{
  .datesubb{ position: initial; }
}