.CompareModal {
	.modal-dialog {
		max-width: 416px;
		.modal-content {
			min-height: 711px;
			.modal-body {
				h2 {
					padding-top: 24px;
				}
				// .custom-Compare{
				//     height: 538px;
				.img-comp-container {
					position: relative;
					height: 530px;
					/*should be the same height as the images*/
					.loaderImg {
						position: absolute !important;
						top: 50%;
						left: 0;
						right: 0;
						margin: 0 auto;
					}
				}
				.img-comp-img {
					position: absolute;
					width: auto;
					height: auto;
					overflow: hidden;
					cursor: move;
				}
				.img-comp-img img {
					display: block;
					vertical-align: middle;
					object-fit: cover;
				}
				.grayscaleLeft {
					position: absolute;
					bottom: 15px;
					left: -4px;
					display: inline-block;
					.halfLeft {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: #d84d95;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(90deg);
						position: absolute;
						top: 0;
						right: -20px;
						z-index: 9999;
					}
					.halfRight {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: #ffffff;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(-90deg);
					}
				}
				.grayscaleLeftblack {
					position: absolute;
					bottom: 15px;
					left: -4px;
					display: inline-block;
					.halfLeft {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: #000000;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(90deg);
						position: absolute;
						top: 0;
						right: -20px;
					}
					.halfRight {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: #ffffff;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(-90deg);
					}
				}
				.grayscaleRight {
					position: absolute;
					bottom: 15px;
					right: 15px;
					display: inline-block;
					.halfLeft {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: #000000;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(90deg);
						position: absolute;
						top: 0;
						right: -20px;
					}
					.halfRight {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: transparent;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(-90deg);
					}
				}
				.grayscaleRightPink {
					position: absolute;
					bottom: 15px;
					right: 15px;
					display: inline-block;
					.halfLeft {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: #d84d95;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(90deg);
						position: absolute;
						top: 0;
						right: -20px;
					}
					.halfRight {
						width: 40px;
						height: 20px;
						/* as the half of the width */
						background-color: transparent;
						border-top-left-radius: 110px;
						border-top-right-radius: 110px;
						border: 1px solid #000000;
						border-bottom: 0;
						transform: rotate(-90deg);
					}
				}
				.img-comp-slider {
					position: absolute;
					z-index: 9;
					cursor: ew-resize;
					/*set the appearance of the slider:*/
					width: 40px;
					height: 40px;
					background-color: #2196F3;
					opacity: 0.7;
					border-radius: 50%;
					background-color: transparent;
					opacity: 0.7;
					border-radius: 50%;
					border: 2px solid #ffff;
					&:before {
						content: "\e903";
						font-family: 'oralift' !important;
						position: absolute;
						top: 9px;
						left: 4px;
						transform: rotate(90deg);
						color: #ffffff;
						font-size: 13px;
					}
					&:after {
						content: "\e905";
						font-family: 'oralift' !important;
						position: absolute;
						color: #ffffff;
						top: 9px;
						right: 4px;
						font-size: 13px;
					}
				}
				//     div{
				//         &:first-child{
				//             height: 100%!important;
				//     }
				// }
				// }
			}
			.icon-css {
				float: right;
			}
		}
	}
}