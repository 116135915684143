@import "./../../../assets/scss/variables.scss";

.main-AdminProgress {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;

  .inner-HomeProgress {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);

    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 40px 0 10px 0;
      width: 100%;
      // display: table;

      .weekly-Data {
        margin-bottom: 30px;

        .weekly-List {
          overflow-x: scroll;
          overflow-y: hidden;
          height: 80px;
          white-space: nowrap;
          max-width: 945px;
        }
      }

      .dropdown-Section {
        display: table;
        width: 100%;

        .dropdowncomponent {
          width: 17%;
          border-radius: 50px;
          padding: 0 10px 0 10px;
          border: 1px solid $bordeColor;
          float: right;

          &:nth-child(2) {
            margin-left: 10px;
          }

          &:after {
            content: "";
            width: 1px;
            height: 34px;
            background: $bordeColor;
            position: absolute;
            top: 0;
            right: 30px;
          }

          .dropdownButtom {
            button {
              &.dropdown-toggle {
                &:before {
                  top: 7px;
                }

                color: $innerColor;
              }
            }
          }
        }
      }

      .main-HighChart-Section {
        // display: flex;
        // justify-content: space-between;

        .width100 {
          width: 100%;
          background-color: $whiteColor;
          padding: 10px 0 10px 0;
          margin-bottom: 24px;

          .title {
            color: $innerColor;
            font-size: $fontSize16;
            font-weight: 400;
            margin: 0 0 0 15px;
          }

          .choices {
            font-size: $fontSize14;
            color: $labelColor;
            font-weight: 400;
            cursor: pointer;

            &.mLeft10 {
              margin-left: 10px;
            }

            &.active {
              color: $secondaryColor;
              border-bottom: 1px solid;
              padding-bottom: 12px;
            }
          }

          .dropdown-Section {
            display: flex;
            justify-content: space-between;
            padding: 0 15px 10px 15px;
            border-bottom: 1px solid $bordeColor !important;

            .dropdowncomponent {
              width: 26%;

              .dropdown-toggle {
                padding: 0;
              }

              .dropdownButtom {
                button {
                  &.dropdown-toggle {
                    &:before {
                      top: 6px;
                      font-size: $fontSize10;
                    }

                    color: $innerColor;
                  }
                }
              }

              &:after {
                content: "";
                width: 1px;
                height: 24px;
                background: $bordeColor;
                position: absolute;
                top: 0;
                right: 30px;
              }
            }

            &.right {
              justify-content: flex-end;
              border: none !important;
            }

            .iconupsec {
              display: none;
              padding-left: 30px;

              .label {
                font-size: $fontSize12;
                align-items: center;
                display: flex;

                .icon-css {
                  margin-right: 5px;
                }

                &.mLeft20 {
                  margin-left: 20px;
                }
              }
            }
          }

          .iconsec {
            display: flex;
            padding-left: 30px;

            .label {
              font-size: $fontSize12;
              align-items: center;
              display: flex;

              .icon-css {
                margin-right: 5px;
              }

              &.mLeft20 {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-AdminProgress {
    .inner-HomeProgress {
      .main-Container {
        max-width: 731px;
        // padding: 70px 0 72px 0;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-AdminProgress {
    .inner-HomeProgress {
      .main-Container {
        max-width: 731px;
        // margin-left: 95px;
        // padding: 70px 0 72px 0;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-AdminProgress {
    display: block !important;

    .inner-HomeProgress {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;

      .main-Container {
        max-width: 667px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-AdminProgress {
    display: block !important;

    .inner-HomeProgress {
      width: 100% !important;
      // padding-top: 15px;
      // padding-bottom: 55px !important;
      display: block !important;

      .main-Container {
        width: 625px;
        // padding: 85px 0 0px 0;

        .main-HighChart-Section {
          flex-wrap: wrap;

          .width100 {
            flex: 0 0 100%;
            margin-bottom: 20px;
            padding: 10px 0 0 0;
            .dropdown-Section {
              .iconupsec {
                display: inline-flex;
              }
            }
          }
          .iconsec {
            display: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-AdminProgress {
    display: block !important;

    .inner-HomeProgress {
      width: 100% !important;
      // padding-top: 15px;
      padding-bottom: 0px !important;
      display: table !important;

      .main-Container {
        width: 452px !important;
        .dropdown-Section {
          .dropdowncomponent {
            width: 25%;
          }
        }
        // padding: 30px 0 0px 0;
        .main-HighChart-Section {
          flex-wrap: wrap;

          .width100 {
            flex: 0 0 100%;
            margin-bottom: 20px;
            padding: 10px 0 0 0;
            .dropdown-Section {
              .iconupsec {
                display: inline-flex;
                padding-left: 0px;
                padding-top: 3px;
                .mLeft20 {
                  margin-left: 5px !important;
                }
              }
            }
          }
          .iconsec {
            display: none !important;
          }
        }
      }
    }
  }
}
