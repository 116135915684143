@import "../../assets/scss/variables.scss";

// checkbox css
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: $bordeColor;
  vertical-align: middle;
  border: 1px solid $bordeColor;
  border-radius: 0;
  // padding: 2px;
  // margin:0 5px;
  border-radius: 4px;
  margin-right: 15px;
  position: absolute;
  left: 0;
  top: 0;
}

input[type="checkbox"]:checked+label span:before {
  content: '\e901';
  position: absolute;
  font-family: 'oralift';
  top: 0px;
  font-size: 12px;
  left: 3px;
  color: $secondaryColor;
}

input[type="checkbox"]:checked+label span.label-Css:before {
  display: none !important;
}