@import "../../assets/scss/variables.scss";
.customModal{
    .modal-dialog{
        max-width: 395px;
        .modal-content{
                padding: 24px 0;
                border:none;
                border-radius: 10px;
            .modal-body{
                text-align: center;
                max-width: 292px;
                margin: 0 auto;
                display: table;
                padding: 0;
                
                h4{
                    color:$secondaryColor !important;
                    
                }
            }
            .modal-footer{
                border:none;
                padding: 0;
                text-align: center;
                display: table;
                .submitBtn{
                    width: 250px!important;
                    height: 48px;
                    display: block;
                    margin: 10px auto;
                }
            }
        }
    }
}

