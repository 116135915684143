@import "./../../../assets/scss/variables.scss";
.main-QuestionBiweekly-view{
     width: 100%;
    display: inline-block;
      background: #F6F7FB;
          height: 100vh;
    .inner-QuestionBiweekly{
         height: calc(100vh - 80px);
        display: inline-block;
        width: calc(100% - 312px);  
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 180px 0 0px 0;
            width:100%;
            // display: table;
            .upload-Button {
                position: relative;
                span {
                  float: left;
                  border: 1px solid #3776fd;
                  box-sizing: border-box;
                  border-radius: 45px;
                  color: #3776fd;
                  padding: 6px 16px;
                  margin-bottom: 20px;
                  cursor: pointer;
                }
      
                @media (max-width: 1024px) {
                  padding-bottom: 30px;
                }
                
                }
            .img-Container{
                width: 140px;
                height: 126px;
                padding-bottom: 40px;
                display: table;
            }
             .question-Form{
            display: table;
            width: 100%;
            padding-bottom: 40px;
               
           }
            
           
        }
        .btn{
            margin-bottom: 0px;
        }
}

    }
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-QuestionBiweekly-view {
            .inner-QuestionBiweekly{
                .main-Container{
                    max-width: 731px;
                    padding: 120px 0 130px 0;
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-QuestionBiweekly-view {
            .inner-QuestionBiweekly{
                .main-Container{
                    max-width: 667px;
                    // margin-left: 95px;
                    padding: 120px 0 130px 0;
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-QuestionBiweekly-view {
            display: block !important;
            .inner-QuestionBiweekly{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 100px 0 137px 0;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 0;
                        width: 60%;
                        float: left;
                        padding-left: 61px;
                    }
                    .checkbox-Section{
                        margin-bottom: 25px !important;
                    }
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-QuestionBiweekly-view {
            display: block !important;
            .inner-QuestionBiweekly{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 100px 0 110px 0;
                    // margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 25px;
                        width: 100%;
                        float: left;
                        padding-left: 0;
                    }
                    .checkbox-Section{
                        margin-bottom: 25px !important;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-QuestionBiweekly-view {
            display: block !important;
            .inner-QuestionBiweekly{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 05px !important;;
                display: table!important;;
                .main-Container{
                    width: 452px !important;
                    padding: 80px 0 90px 0;
                    margin-left: 10% !important;
                    .btn{
                        float: left;
                    }
                    .upgrade-Section{
                        padding-top: 25px;
                        width: 100%;
                        float: left;
                        padding-left: 0;
                    }
                }
            }
        }
    }