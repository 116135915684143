@import "./../../../assets/scss/variables.scss";

.main-5mmGuide {
    width: 100%;
    display: inline-block;
      background: $bgColor;
          height: 100vh;
    .inner-5mmGuide {
        height: calc(100vh - 80px);
        display: inline-block;
        width: calc(100% - 312px);  
        .main-Container {
            max-width: 945px;
            margin: 0 auto;
            padding: 40px 0 10px 0;
            width: 100%;
            display: table;
    }

}
}
@media only screen and (min-width:1280px) and (max-width:1450px) {
    .main-5mmGuide {
        .inner-5mmGuide {
            .main-Container {
                max-width: 731px;
            }
        }
    }
}

@media only screen and (min-width:1025px) and (max-width:1281px) {
    .main-5mmGuide {
        .inner-5mmGuide {
            .main-Container {
                max-width: 667px;

            }
        }
    }
}

@media only screen and (min-width:993px) and (max-width:1024px) {
    .main-5mmGuide {
        display: block !important;
        .inner-5mmGuide {
            width: 100%;
            padding-top: 15px;
            padding-bottom: 55px !important;
            display: block !important;
            .main-Container {
                max-width: 667px;
                .paddingB {
                    padding-bottom: 30px;
                }
                
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .main-5mmGuide {
        display: block !important;

        .inner-5mmGuide {
            width: 100% !important;
            display: block !important;

            .main-Container {
                width: 625px;
            }
        }
    }
}

@media only screen and (min-width:500px) and (max-width:767px) {
    .main-5mmGuide {
        display: block !important;

        .inner-5mmGuide {
            width: 100% !important;
            padding-bottom: 0px !important;
            display: table !important;

            .main-Container {
                width: 452px !important;

                .paddingB {
                    padding-bottom: 40px;
                }

            }
        }
    }
}