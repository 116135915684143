@import "../../assets/scss/variables.scss";
.sideMenu{
 padding-top: 40px;
      display: flex;
      width: 312px !important;
      float: left;
      height: 100vh;
      background: $whiteColor;
    .sideMenuOptions{
      width: 100%;
      background: $whiteColor;
        .img-Logo{
          display: block;
          padding-bottom: 45px;
          width: 100%;
          text-align: center;
          .icon-close{
            position: absolute;
            top:15px;
            right: 15px;
            font-size: 13px;
            color: $closeColor;
            cursor: pointer;
            display: none;
          }
        }
      .menuScroll{
        height: 80% !important;
      }
      .innerMenu{
        list-style-type: none;
        padding: 0;
        padding-left: 25px;
        
        li{
         &.active{
          border: 1px solid transparent;
          height: 48px;
          border-radius: 45px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background: $primaryColor;
         a{
          color:$whiteColor;
          
         }
         }
          a{
            font-size: 18px;
            color:$secondaryColor;
            padding: 10px 10px;
            display: block;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
}

@media only screen and (max-width:1024px) {
  .sideMenu{
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 312px;
    text-align: left;
    height:calc(100%);
    background:$whiteColor;
    top:0px;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    z-index: 11;

}
}

// ::-webkit-scrollbar { 
//     display: none; 
// }