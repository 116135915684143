@import "../../assets/scss/variables.scss";
.home-AdminsideMenu {
    display: inline-block;
    max-width: 312px !important;
    float: left;
    height: 100vh;
    background: $whiteColor;
    // box-shadow: 0 0 8px -2px rgba(0,0,0,0.65);
     box-shadow: 0 0 8px -8px rgba(0,0,0,0.65);
    -webkit-box-shadow: 0 0 8px -8px rgba(0,0,0,0.65);
    -moz-box-shadow:  0 0 8px -8px rgba(0,0,0,0.65);
    width: 100%;
    .sideMenuOptions {
        width: 100%;
        background: $whiteColor;
        .grid-Details {
            display: block;
            text-align: center;
            width: 100%;
        // padding-bottqom: 123px;
             padding-top: 80px;
                height: 364px;
            .img-Profile {
                width: 60px;
                height: 60px;
                display: table;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            .user-Details {
                margin-top: 16px;
                display: block;
                 h2{
               font-size: $fontSize16 !important;
               padding-bottom: 8px ;
             }
                .innerTitle {
                    margin-bottom: 8px;
                    &.lightG {
                        color: $lightGrey;
                    }
                }
            }
            .icon-close {
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 13px;
                color: $closeColor;
                cursor: pointer;
                display: none;
            }
        }
        .menuScroll {
            // height: 100vh !important;
            height: calc(100vh - 430px) !important;
        }
        .innerMenu {
            list-style-type: none;
            padding: 0;
            padding-left: 25px;
            // background: red;
            li {
                &.active {
                    border: 1px solid transparent;
                    height: 48px;
                    border-radius: 45px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    background: $primaryColor;
                    a {
                        color: $whiteColor;
                    }
                }
                a {
                    font-size: 18px;
                    color: $secondaryColor;
                    padding: 10px 10px;
                    display: block;
                    text-decoration: none;
                    cursor: pointer;
                    span {
                        &.icon-css {
                            float: right;
                            padding-right: 10px;
                            padding-top: 8px;
                        }
                        &.icon-up {
                            -webkit-transform: rotate(-180deg);
                            -moz-transform: rotate(-180deg);
                            -ms-transform: rotate(-180deg);
                            -o-transform: rotate(-180deg);
                            color: $bgColor;
                            margin-right: 10px;
                            margin-top: 5px;
                        }
                        &.icon-down {
                            color: $bgColor;
                        }
                    }
                }
                .moreOptions {
                    display: table;
                    .moreUl {
                        display: table;
                        .moreLi {
                            list-style-type: none;
                            a {
                                color: #444444;
                                font-size: $fontSize16;
                                padding: 12px 10px;
                            }
                            &.active {
                                background: transparent !important;
                                a {
                                    color: $secondaryColor !important;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
            .dropdowncomponent {
                border: none;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
  .home-AdminsideMenu {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 312px;
    text-align: left;
    height: calc(100%);
    background: $whiteColor;
    top: 0px;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    z-index: 11;
  }
}

// ::-webkit-scrollbar {
//     display: none;
// }
