@import "../../assets/scss/variables.scss";
.headerResponsive{
    display: table;
    width: 100%;
    height: 60px;
    background: $whiteColor;
    display: none;
    .icon-menu{
        position: absolute;
        top: 20px;
        left: 26px;
        font-size: 19px;
        cursor: pointer;
    }
}
@media only screen and (max-width:1024px) {
    .headerResponsive{
        display: table;
    }
}