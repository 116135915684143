// Bg Color

$bgColor: #f6f7fb;
// Color

$primaryColor: #d84d95;
$secondaryColor: #590459;
$innerColor: #444444;
$blueColor: #3776fd;
$lightGrey: #a2a2a2;
$bordeColor: #d8d8d8;
$whiteColor: #ffffff;
$closeColor: #3d3d3d;
$labelColor: #6b6b6b;
$placeholder: #d3d3d3;
$lightBg: #eeeeee;
$greenColor: #5aab00;
$errorRed: #ff5757;
$lightBorder: #e9ebf2;
$lightPurple: #a77ca8;
$lightwhite: #e4e4e4;
$darkpurple: #5e075c;
$bordergrey: #e9e9e9;

// Font Size
$fontSize60: 60px;
$fontSize30: 30px;
$fontSize24: 24px;
$fontSize20: 20px;
$fontSize18: 18px;
$fontSize16: 16px;
$fontSize13: 13px;
$fontSize12: 12px;
$fontSize14: 14px;
$fontSize10: 10px;

$primary-font-family: "Roboto";

.colorP {
  color: $primaryColor !important;
}

.colorS {
  color: $secondaryColor !important;
}

.colorBgP {
  background: $primaryColor !important;
}

.colorBgS {
  background: $secondaryColor !important;
}

h1 {
  font-size: $fontSize30 !important;
  color: $secondaryColor;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

h2 {
  font-size: $fontSize24 !important;
  color: $secondaryColor;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

h3 {
  font-size: $fontSize20 !important;
  color: $innerColor;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

h4 {
  font-size: $fontSize20 !important;
  color: $innerColor;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

h5 {
  font-size: $fontSize16 !important;
  color: $innerColor;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  line-height: 25px !important;
}
.loaderImg{
  position: absolute !important;
}
.form-label {
  font-size: $fontSize14;
  color: $innerColor;
  font-weight: 400;
  display: table;
}

.innerTitle {
  font-size: $fontSize14;
  color: $innerColor;
  font-weight: 400;
}

.aLink {
  font-size: 16px !important;
  color: $blueColor !important;
  font-weight: 400 !important;
  cursor: pointer;
  padding-top: 5px;
}

.nextLink {
  font-size: 20px !important;
  color: $secondaryColor;
  font-weight: 500 !important;
  line-height: 30px !important;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
  padding-right: 20px;
}

.floatR {
  float: right;
}

.paddingB10 {
  padding-bottom: 10px;
}

.padding0 {
  padding: 0 !important;
}

.paddingB15 {
  padding-bottom: 15px;
}

.paddingT15 {
  padding-top: 15px;
}

.paddingT24 {
  padding-top: 24px;
}

.paddingB40 {
  padding-bottom: 40px;
}

.margin0 {
  margin: 0 !important;
}

.marginT10 {
  margin-top: 10px !important;
}

.highlighted {
  color: $secondaryColor;
  font-weight: 500 !important;
}

.paddingB24 {
  padding-bottom: 24px;
}

.textCapital {
  text-transform: uppercase;
  font-size: $fontSize20;
}

.cursor {
  cursor: pointer;
  position: relative;
}

.icon-css {
  font-size: $fontSize14;
  color: $secondaryColor;
}

.paddingB25 {
  padding-bottom: 25px;
}

.paddingB32 {
  padding-bottom: 32px;
}

.mainScroll {
  position: relative !important;
}

.alignC {
  text-align: center;
}

.fontM {
  font-weight: 500 !important;
}
.error-msg {
  text-align: left;
  display: table;
  color: $errorRed;
  font-size: $fontSize14;
  font-weight: 400;
}
.icon-eyeoff {
  cursor: pointer;
}
.icon-eye {
  cursor: pointer;
}
button:disabled {
  background: $placeholder !important;
}
.modal-backdrop {
  background-color: #989898 !important;
}

// sidebar responsive
.toggleOn {
  .sideMenu {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    z-index: 11;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    height: 100vh;

    .sideMenuOptions {
      .icon-close {
        display: none;
      }
    }
  }

  // }
  //  .toggleOn {
  .home-AdminsideMenu {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    z-index: 11;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    height: 100vh;

    .sideMenuOptions {
      .icon-close {
        display: none;
      }
    }
  }
  .home-sideMenu {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    z-index: 11;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
    height: 100vh;

    .sideMenuOptions {
      .icon-close {
        display: none;
      }
    }
  }
}

.cust-Label {
  font-size: $fontSize14 !important;
  color: $labelColor !important;
  font-weight: 400 !important;
  display: table !important;
  margin: 0 !important;
  line-height: 16px;
}

// custom scroll css
// ::-webkit-scrollbar {
//     display: none;
// }
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $placeholder;
  border-radius: 10px;
}

// End custom scroll css

// placeholder input css
::-webkit-input-placeholder {
  color: $placeholder !important;
  font-size: $fontSize16;
  font-family: $primary-font-family;
}

::-moz-placeholder {
  color: $placeholder !important;
  font-size: $fontSize16;
  font-family: $primary-font-family;
  font-weight: 400;
}

:-ms-input-placeholder {
  color: $placeholder !important;
  font-size: $fontSize16;
  font-family: $primary-font-family;
  font-weight: 400;
}

:-moz-placeholder {
  color: $placeholder !important;
  font-size: $fontSize16;
  font-family: $primary-font-family;
  font-weight: 400;
}

.tool-Tips {
  display: inline-block;
  padding: 0 6px 0 15px;
  width: auto;
  float: none;
  vertical-align: middle;

  a.tip {
    position: relative;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: $lightBg;
    display: table;

    &.icon-info {
      &:before {
        padding: 5px 7px;
        display: block;
        color: $primaryColor;
        font-size: $fontSize18;
        line-height: 22px;
      }
    }
  }
}

a.tip span {
  display: none;
  position: absolute;
  top: 46px;
  left: -280px;
  width: 337px;
  padding: 5px;
  z-index: 100;
  background: $whiteColor;
  color: $innerColor;
  border-radius: 5px;
  font-size: $fontSize13;
  font-family: $primary-font-family;
  font-weight: 400;
  line-height: 18px;
  padding: 16px;
  height: auto;
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.3);

  @media (max-width: 767px) {
    left: -250px;
    width: 300px;
  }
}

a.tip span:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid $whiteColor;
  right: 10%;
  top: -12px;
  transform: rotate(90deg);
  // box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.4);
}

// a:hover.tip {
//     font-size: 99%; /* this is just for IE */
// }

a:hover.tip span {
  display: block;
}
.disabledCss {
  a {
    color: $labelColor !important;
    cursor: default !important;
    pointer-events: none;
  }
}

@media only screen and (max-width: 1024px) {
  .toggleOn {
    .sideMenu {
      .sideMenuOptions {
        .icon-close {
          display: block;
        }
      }
    }
    .home-sideMenu {
      .sideMenuOptions {
        .icon-close {
          display: block;
        }
      }
    }
    .home-AdminsideMenu {
      .sideMenuOptions {
        .icon-close {
          display: block;
        }
      }
    }
  }
}

// range slider css
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  max-width: 278px;
  background: transparent;
  height: 60px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: $secondaryColor;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: $secondaryColor;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: $secondaryColor;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: rgba(216, 77, 149, 1);
  background: -moz-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(216, 77, 149, 1)),
    color-stop(100%, rgba(89, 4, 89, 1))
  );
  background: -webkit-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -o-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -ms-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: linear-gradient(to right, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.6px;
  z-index: 3;
}

input[type="range"]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(216, 77, 149, 1)),
    color-stop(100%, rgba(89, 4, 89, 1))
  );
  background: -webkit-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -o-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -ms-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: linear-gradient(to right, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: rgba(216, 77, 149, 1);
  background: -moz-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(216, 77, 149, 1)),
    color-stop(100%, rgba(89, 4, 89, 1))
  );
  background: -webkit-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -o-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: -ms-linear-gradient(left, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  background: linear-gradient(to right, rgba(216, 77, 149, 1) 0%, rgba(89, 4, 89, 1) 100%);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.6px;
  z-index: 3;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $secondaryColor;
}

input[type="range"]::-ms-fill-lower {
  background: $secondaryColor;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
  background: $secondaryColor;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type="range"]:focus::-ms-fill-lower {
  background: $secondaryColor;
}

input[type="range"]:focus::-ms-fill-upper {
  background: $secondaryColor;
}
