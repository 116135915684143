@import "./../../../assets/scss/variables.scss";

.main-Entry {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;

  .inner-Entry {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);

    .main-Container {
      max-width: 945px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 397px;

      .btn-choice {
        position: absolute;
        top: -110px;

        .choices {
          font-size: $fontSize14;
          color: $labelColor;
          font-weight: 400;
          cursor: pointer;

          &.mLeft10 {
            margin-left: 10px;
          }
        }
      }

      .img-Container {
        width: 140px;
        height: 126px;
        padding-bottom: 40px;
        display: table;
      }

      .question-Form {
        padding-top: 15px;
        padding-bottom: 40px;

        .customMask {
          background: none !important;
          border: none !important;
          border-bottom: 1px solid #d8d8d8 !important;
          border-radius: 0;
          padding: 0;
          font-size: 16px;
          color: #444444;
          font-weight: 500;
          font-family: "Roboto";
          width: 100%;
          outline: none;
         // margin-top: 7px;
        }

        .form-group {
          margin: 0;
        }

        .mTop10 {
          margin-top: 20px;
        }
        .custom-Datepicker-Device{
          margin-top: -2px;
        }
        .custom-Datepicker {
          padding-top: 7px;
          .react-datepicker-wrapper {
            width: 100%;
            // padding-top: 7px;

            .react-datepicker__input-container {
              input {
                width: 100%;
                border: none;
                background: transparent;
                border-bottom: 1px solid $bordeColor;
                font-size: $fontSize16;
                color: $innerColor;
                font-weight: 500;
                font-family: $primary-font-family;

                &:focus {
                  outline: none;
                }
              }
            }
          }

          .react-datepicker-popper {
            .react-datepicker {
              font-family: $primary-font-family;
              border: none;
              width: 250px;

              .react-datepicker__month-container {
                width: 250px;

                .react-datepicker__header {
                  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.05);
                  -webkit-box-shadow:0 2px 8px 2px rgba(0, 0, 0, 0.05);
                  -moz-box-shadow:0 2px 8px 2px rgba(0, 0, 0, 0.05);
                  border: none;
                  background: #ffffff;
                  border-radius: 0;
                  font-family: $primary-font-family;

                  .react-datepicker__day-name {
                    font-family: $primary-font-family;
                    font-weight: 400;
                    font-size: 12px;
                  }
                }

                .react-datepicker__month {
                  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
                  font-family: $primary-font-family !important;
                  margin: 0;
                  padding: 10px 0 0;

                  .react-datepicker__day--today {
                    background: $primaryColor;
                    border-radius: 16px;
                    color: #fff;
                  }

                  .react-datepicker__day {
                    font-family: $primary-font-family;

                    &:hover {
                      background: $primaryColor;
                      border-radius: 16px;
                      color: #fff;
                    }
                  }

                  .react-datepicker__day--selected {
                    background: $primaryColor;
                    border-radius: 16px;
                    color: #fff;
                  }
                }
              }

              .react-datepicker__triangle {
                display: none;
              }
            }
          }

          .react-datepicker-popper[data-placement^="bottom"] {
            margin-top: 0;
          }
        }
      }
    }

    .btn {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-Entry {
    .inner-Entry {
      .main-Container {
        max-width: 731px;
        // padding: 220px 0 0 0;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-Entry {
    .inner-Entry {
      .main-Container {
        max-width: 667px;
        // padding: 220px 0 0x 0;

        // .content-Section {
        //   padding-bottom: 15px;
        // }
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Entry {
    display: block !important;

    .inner-Entry {
      width: 100%;
      // padding-top: 15px;
      display: table !important;

      .main-Container {
        max-width: 667px;
        // padding: 220px 0 0 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Entry {
    display: block !important;

    .inner-Entry {
      width: 100% !important;
      // padding-top: 15px;
      display: block !important;

      .main-Container {
        width: 625px;
        // padding: 200px 0 0 0;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Entry {
    display: block !important;

    .inner-Entry {
      width: 100% !important;
      padding-top: 15px;
      display: table !important;

      .main-Container {
        width: 452px !important;
        //padding: 200px 0 0 0;


      }
    }
  }
}