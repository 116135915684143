@import "./../../../assets/scss/variables.scss";

.main-HomeProgress {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;

  .inner-HomeProgress {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);

    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 40px 0 10px 0;
      width: 100%;
      // display: table;

      .inline {
        .dropdown-Section {
          margin-top: -95px;
          margin-bottom: 65px;
        }
      }

      .weekly-Data {
        margin-bottom: 30px;

        .weekly-List {
          overflow-x: scroll;
          overflow-y: hidden;
          height: auto;
          white-space: nowrap;
          max-width: 78%;
          padding-bottom: 15px;
        }
      }

      .dropdown-Section {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .divclass {
          &.show {
            position: fixed;
            width: calc(100% - 312px);
            height: calc(100vh - 80px);
            background: #f3f3f314;
            bottom: 0;
          }
        }
        .tool-Tips {
          a {
            &.tip {
              margin-right: 20px;
            }
          }
          &.show {
            a {
              span {
                display: block !important;
              }
            }
          }
          a {
            span {
              display: none !important;
            }
          }
        }

        .dropdowncomponent {
          display: inline-flex;
          width: 16%;
          border-radius: 50px;
          padding: 0 10px 0 10px;
          border: 1px solid $bordeColor;
          float: right;

          &:nth-child(2) {
            margin-left: 10px;
          }

          &:after {
            content: "";
            width: 1px;
            height: 34px;
            background: $bordeColor;
            position: absolute;
            top: 0;
            right: 30px;
          }

          .dropdownButtom {
            button {
              &.dropdown-toggle {
                // text-align: center;
                &:before {
                  top: 7px;
                }

                color: $innerColor;
                @media only screen and (min-width: 1025px) and (max-width: 1281px) {
                  font-size: $fontSize12;
                }
              }
            }
          }
          .dropdown-toggle {
            width: 45%;
          }
        }
      }

      .main-HighChart-Section {
        display: table;
        width: 100%;
        .width50 {
          width: 100%;
          background-color: $whiteColor;
          padding: 10px 0 0 0;
          position: relative;

          .biweeklyAlert {
            font-size: 12px;
            background: #e6dfeb;
            width: 100%;
            height: 32px;
            margin: 0;
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;

            .icon-css {
              margin: 0 8px;
            }
          }

          .title {
            color: $innerColor;
            font-size: $fontSize16;
            font-weight: 400;
            // line-height: 0 !important; // padding-bottom: 20px;
            margin: 0 0 0 15px;
          }

          .choices {
            font-size: $fontSize14;
            color: $labelColor;
            font-weight: 400;

            &.mLeft10 {
              margin-left: 10px;
            }

            &.active {
              color: $secondaryColor;
              // border-bottom: 1px solid;
              padding-bottom: 12px;
            }
          }

          .dropdown-Section {
            display: flex;
            justify-content: space-between;
            padding: 0 15px 10px 15px;
            border-bottom: none;

            .ampmSection {
              .list-icon-square-purple {
                height: 12px;
                width: 12px;
                display: inline-block;
                background: $secondaryColor;
              }
              .list-icon-square-lp {
                height: 12px;
                width: 12px;
                display: inline-block;
                background: $lightPurple;
              }
            }

            .dropdowncomponent {
              width: 20%;

              .dropdown-toggle {
                padding: 0;
              }

              .dropdownButtom {
                button {
                  &.dropdown-toggle {
                    &:before {
                      top: 6px;
                      font-size: $fontSize10;
                    }

                    color: $innerColor;
                    text-align: center;
                  }
                }
              }

              &:after {
                content: "";
                width: 1px;
                height: 24px;
                background: $bordeColor;
                position: absolute;
                top: 0;
                right: 30px;
              }
            }

            &.right {
              justify-content: flex-end;
              border: none !important;
            }

            .iconupsec {
              display: none;
              padding-left: 30px;

              .label {
                font-size: $fontSize12;
                align-items: center;
                display: flex;

                .icon-css {
                  margin-right: 5px;
                }

                &.mLeft20 {
                  margin-left: 20px;
                }
              }
            }
          }

          .iconsec {
            display: flex;
            padding-left: 30px;
            margin-bottom: 35px;

            .label {
              font-size: $fontSize12;
              align-items: center;
              display: flex;

              .icon-css {
                margin-right: 5px;
              }

              &.mLeft20 {
                margin-left: 20px;
              }
            }
          }
          .highChart-Section {
            position: relative;
            .noData {
              position: absolute;
              font-size: $fontSize14;
              left: 50%;
              z-index: 1;
              top: 42%;
              font-weight: 400;
              color: $lightGrey;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-HomeProgress {
    .inner-HomeProgress {
      .main-Container {
        max-width: 731px;
        // padding: 70px 0 72px 0;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-HomeProgress {
    .inner-HomeProgress {
      .main-Container {
        max-width: 667px;
        // margin-left: 95px;
        // padding: 70px 0 72px 0;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-HomeProgress {
    display: block !important;

    .inner-HomeProgress {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;

      .main-Container {
        max-width: 667px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-HomeProgress {
    display: block !important;

    .inner-HomeProgress {
      width: 100% !important;
      // padding-top: 15px;
      // padding-bottom: 55px !important;
      display: block !important;

      .main-Container {
        width: 625px;
        // padding: 85px 0 0px 0;

        .main-HighChart-Section {
          flex-wrap: wrap;

          .width50 {
            flex: 0 0 100%;
            margin-bottom: 20px;
            padding: 10px 0 0 0;
            .dropdown-Section {
              .iconupsec {
                display: inline-flex;
              }
            }
          }
          .iconsec {
            display: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-HomeProgress {
    display: block !important;

    .inner-HomeProgress {
      width: 100% !important;
      // padding-top: 15px;
      padding-bottom: 0px !important;
      display: table !important;

      .main-Container {
        width: 94% !important;
        margin-left: 3%;
        margin-right: 3%;
        .weekly-Data {
          .weekly-List {
            width: 70%;
          }
        }
        .dropdown-Section {
          .dropdowncomponent {
            width: 25%;
            padding-top: 1px;
            padding-bottom: 3px;
          }
        }
        // padding: 30px 0 0px 0;
        .main-HighChart-Section {
          flex-wrap: wrap;

          .width50 {
            flex: 0 0 100%;
            margin-bottom: 20px;
            padding: 10px 0 0 0;
            .dropdown-Section {
              .iconupsec {
                display: inline-flex;
                padding-left: 0px;
                padding-top: 3px;
                .mLeft20 {
                  margin-left: 5px !important;
                }
              }
            }
          }
          .iconsec {
            display: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .main-HomeProgress {
    .inner-HomeProgress {
      .main-Container {
        .inline {
          .dropdown-Section {
            margin-top: -97px;
            margin-bottom: 65px;
          }
        }
        .dropdown-Section {
          .dropdowncomponent {
            padding: 2px 10px 3px 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
.main-HomeProgress {
  .inner-HomeProgress {
    width: 100%;
  }
}
.main-HomeProgress .inner-HomeProgress .main-Container .dropdown-Section .dropdowncomponent {
	width: 30%;
}
.main-HomeProgress .inner-HomeProgress .main-Container .main-HighChart-Section .width50 .dropdown-Section .dropdowncomponent {
	width: 30%;
}
.main-HomeProgress .inner-HomeProgress .main-Container .weekly-Data .weekly-List {
	max-width: 100%;
}
a.tip span.info-detail {
	left: auto;
	max-width: 190px;
	right: -10px;
	font-size: 11px;
	line-height: 13px;
}
.legendsTable tr th img { max-width: 45px; }

.inner-HomeProgress #calendar, .inner-HomeProgress #calendar tr, .inner-HomeProgress #calendar tbody {
	grid-template-columns: repeat(7, 1fr);
}
.inner-HomeProgress #calendar tr {
	grid-column: 1 / -1;
}
.inner-HomeProgress #calendar .weekdays, .inner-HomeProgress #calendar .other-month {
	display: grid;
}
.inner-HomeProgress #calendar .weekdays th {
	padding: 5px 1px;
	font-size: 11px;
}
.inner-HomeProgress #calendar td {
	min-height: 48px;
}
.inner-HomeProgress #calendar .date {
	font-size: 13px;
}
.inner-HomeProgress .timeBox {
	border: 0;
	margin-bottom: 0;
	padding: 5px 2px;
}

.inner-HomeProgress .manageHalf .col-half {
	width: 100%;
	padding: 1px 5px;
}
.inner-HomeProgress .manageHalf {
	display: flex;
	flex-wrap: wrap;
}
.inner-HomeProgress .boxwith_times {
	min-height: 45px;
}
.inner-HomeProgress .touchesBox {
	width: 16px;
	height: 16px;
	line-height: 15px;
}
.inner-HomeProgress .firstbx {
	margin-top: -22px;
}

.legendsInMobile .legendsTable tr th {
	width: 68px;
}
.legendsTable tr td {
	font-size: 11px;
	line-height: 12px;
}

.legendsInMobile {
	position: fixed;
	bottom: 0;
	background: #f6f7fb;
	width: 100%;
	left: 0;
	padding: 0 15px;
}

#calendar {
	padding-bottom: 120px;
}
.main-HomeProgress .inner-HomeProgress {
	display: inline-block !important;
}
}