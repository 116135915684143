@import "../../assets/scss/variables.scss";
#newhighchartcontainer{
    position: relative;
   
}

.isLoading{
    background-color: antiquewhite;
}

.loader-container {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}


.loader-container::after {
    display:block;
    position:absolute;
    left:48%;top:40%;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    animation: spin .8s linear infinite;
}