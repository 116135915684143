@import "./../../assets/scss/variables.scss";

.main-Profile {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-Profile {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 40px 0 10px 0;
      width: 100%;
      position: absolute; /*it can be fixed too*/
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: $whiteColor;
      height: 482px;
      width: 385px;
      padding: 40px;
      text-align: center;

      .tooltip_main{
        display: flex;
    position: relative;
    float: right;
    margin-right: -13%;
    margin-top: -11%;
        .divclass{
          &.show {
            position: fixed;
            width: calc(100% - 312px);
            height: calc(100vh - 80px);
            background: #f3f3f314;
            bottom: 0;
          }
        }
        .tool-Tips {
          
          &.show{
            a {
              &.tip{
                height: 30px;
                width: 30px;
              }
              span{
                display: block !important; 
                text-align: left;
               }
            }

          }
          a {
              span{
                display: none !important; 
               }
          }
          
          a.tip {
            width: 30px;
            height: 30px;
           
            &.icon-info {
              &:before {
                padding: 4px 6px;
              }
            }
          }
        }
      }




      .img-upload {
            display: inline-block;
    flex-direction: column;
    
    width: 96px;
    height: 96px;
    align-items: center;
    align-self: center;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;

        .profileimg {
          width: 100%;
          height: 100%;
          align-items: center;
          align-self: center;
          border-radius: 50%;
          border: 1px solid gray;

        }
        .img-edit {
          width: 36px;
          border-radius: 25px;
          height: 36px;
          background-color: $bgColor;
          align-items: center;
          display: flex;
          align-self: center;
          justify-content: center;
          // margin-top: -20px;
          cursor: pointer;
          position: absolute;
    left: 33px;
    bottom: -23px;

          .cameraclass {
            color: $secondaryColor;
          }
          label {
            margin: 0;
            cursor: pointer;
          }
          & > input {
            display: none;
            cursor: pointer;
            height: 100%;
            width: 100%;
          }
        }
      }
      .aLink {
        float: left;
    }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-Profile {
    .inner-Profile {
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-Profile {
    .inner-Profile {
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Profile {
    display: block !important;
    .inner-Profile {
      width: 100%;
      display: block !important;
      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Profile {
    display: block !important;

    .inner-Profile {
      width: 100% !important;
      display: block !important;

      .main-Container {
        width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Profile {
    display: block !important;
    .inner-Profile {
      width: 100% !important;
      display: table !important;

      .main-Container {
        width: 385px !important;
      }
    }
  }
}
