@import "./../../../assets/scss/variables.scss";
.main-SetPreferences{
    display: inline-flex;
    width: 100%;
    .inner-SetPreferences{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
         width: 100% !important;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 30px 0 0 0;
            width: 100%;
            display: table;
            .img-Container{
                padding-bottom: 40px;
                display: table;
            }
           .question-Form{
            display: table;
            width: 100%;
            padding-bottom: 34px;
               .row{
               .main-Title{
                padding-top: 30px;
                padding-bottom: 24px;
               }
               }
               &.paddingB0{
                   padding-bottom: 40px;
               }
           }
           .only-Content{
                   display: table;
                padding-top: 15px;
               .contents{
                   font-size: $fontSize16 ;
                    color:$innerColor;
                    font-weight: 400 !important;
                    margin: 0;
               }
           }
           .content-Section{
               padding-bottom: 8px;
               display: table;
               h5{
                    position: relative;
                    padding-left: 20px;
                    padding-bottom: 8px;
                    &.paddingB15{
                        padding-bottom: 24px;
                    }
                    .dots-Css {
                        width: 8px;
                        height: 8px;
                        background: $secondaryColor;
                        position: absolute;
                        top: 9px;
                        left: 0;
                        border-radius: 50%;
                    }
                }
           }
        }
        .btn{
            // margin-top: 40px;
        }
    }

    }
  
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-SetPreferences {
            .inner-SetPreferences{
                .main-Container{
                    max-width: 731px;
                    padding: 0px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-SetPreferences {
            .inner-SetPreferences{
                .main-Container{
                    max-width: 667px;
                     padding: 0px 0 0px 0!important;
                    .question-Form{
                       padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-SetPreferences {
            display: block !important;
            .inner-SetPreferences{
                width: 100%;
                padding-top: 15px;
                // padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 0px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-SetPreferences {
            display: block !important;
            .inner-SetPreferences{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 10px 0 0px 0;
                  .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-SetPreferences {
            display: table !important;
            .inner-SetPreferences{
                width: 100% !important;
                padding-top: 15px;
                 padding-bottom: 0px !important;
                display: table !important;
                .set-Scroll{
                    height: calc(100vh - 100px) !important;
                }
                .main-Container{
                    width: 452px !important;
                    padding: 10px 0 20px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                    
                }
            }
        }
    }