@import "./../../../assets/scss/variables.scss";
.main-SavePreferences{
    // display: inline-flex;
    // width: 100%;
    width: 100%;
    display: inline-block;
    background: #F6F7FB;
    height: 100vh;
    .inner-SavePreferences{
        // display: inline-flex;
        // width: 100%;
        // height: 100%;
        // height: 100vh;
      
    //     background: $bgColor;
    //      width: 100% !important;
    //      height: calc(100vh - 80px);
    // display: inline-block;
     width: calc(100% - 312px);
      padding-top: 40px;
        height: calc(100vh - 80px);
    display: inline-block;
    background: $bgColor;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 30px 0 10px 0;
            width: 100%;
            display: table;
            .img-Container{
                padding-bottom: 40px;
                display: table;
            }
           .question-Form{
            display: table;
            width: 100%;
            padding-bottom: 0;
               .row{
               .main-Title{
                padding-top: 30px;
                padding-bottom: 24px;
               }
               }
               &.paddingTop{
                   padding-top: 24px;
                   padding-bottom: 24px;
               }
           }
          .wishlist-Details{
              display: table;
                padding-bottom: 24px;
                width: 100%;
              h5{
                  padding-bottom: 8px;
              }
              .list-Details{
                  .list-text{
                       font-size: $fontSize14;
                       color: $innerColor;
                       font-weight: 400;
                       margin: 0;
                       line-height: 24px;
                       padding-bottom: 8px;
                  }
              }
          }
        .edit-Section{
            display: table;
            width: 100%;
            border-bottom:2px solid $placeholder;
            padding-bottom: 24px;
            &.bottomP{
                margin-bottom: 40px;
            }
        }
        }
    }
    &.centerAlign{
        .inner-SavePreferences{
                padding: 150px 0 0 0;
        }
    }
    }
  
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-SavePreferences {
            .inner-SavePreferences{
                .main-Container{
                    max-width: 731px;
                    // padding: 0px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-SavePreferences {
            .inner-SavePreferences{
                .main-Container{
                    max-width: 667px;
                    //  padding: 0px 0 0px 0!important;
                    .question-Form{
                       padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-SavePreferences {
            display: block !important;
            .inner-SavePreferences{
                width: 100%;
                padding-top: 15px;
                // padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    // padding: 0px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-SavePreferences {
            display: block !important;
            .inner-SavePreferences{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 60px 0 0px 0;
                  .question-Form{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-SavePreferences {
            display: block !important;
            .inner-SavePreferences{
                width: 100% !important;
                padding: 0!important;
                display: table !important;
                .testreport-Scroll{
                    height: calc(100vh - 90px) !important;
                }
                .main-Container{
                    width: 452px !important;
                   padding: 60px 0 0px 0;
                    .question-Form{
                        padding-top: 30px;
                    }
                    
                }
            }
        }
    }