@import "../../assets/scss/variables.scss";
.upload-Button {
  .filters {
    position: absolute;
    background: $whiteColor;
    z-index: 9;
    text-align: center;
    top: 45px;

    .calenderfilter {
      padding: 16px 12px;
      width: 739px;

      @media(max-width:1024px){
        width: auto;
      }

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid $whiteColor;
        left: 10%;
        top: -10px;
        transform: rotate(90deg);
      }
      ul {
        padding: 0;
        display: block;
        margin: 0;
        text-align: left;

        &.pbt-6 {
          padding-bottom: 12px;

          @media only screen and (min-width: 500px) and (max-width: 767px) {
            padding-bottom: 0;
          }
          .close-icon{
            border: none;
            color: $closeColor;
            position: absolute;
            top: 7px;
            right: 7px;
            font-size: 13px;
            padding: 0;
          }
        }
        li {
          display: inline-block;
          list-style: none;
          &.filterdate {
            border: 1px solid $bordergrey;
            box-sizing: border-box;
            margin: 4px 4px;
            width: 165px;
            height: 32px;
            padding: 5px 8px;
            font-size: 14px;
            color: $innerColor;
            line-height: 20px;
            text-align: center;

            @media only screen and (min-width: 993px) and (max-width: 1204px) {
              width: 150px;
            }

            @media only screen and (min-width: 768px) and (max-width: 992px) {
              width: 140px;
              padding: 5px;
            }

            @media only screen and (min-width: 500px) and (max-width: 767px) {
              width: 205px;
              margin-bottom: 4px;
            }

            &.selected {
              border: 1px solid #3776fd;
              box-sizing: border-box;
              color: #3776fd;
            }
          }
        }
      }
    }
    .divider {
      border-top: 1px solid $bordergrey;
    }
    .donebtn {
      text-align: right;
      padding: 16px;

      @media only screen and (min-width: 500px) and (max-width: 767px) {
        text-align: center;
      }
      .btn {
        background: linear-gradient(170.73deg, #d84d95 5.07%, #590459 94.64%);
        border-radius: 45px !important;
        border: none !important;
        text-transform: capitalize;
        color: $whiteColor;
        width: 80px;
        height: 30px;
        line-height: 18px;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        text-align: center;
        float:right;
        @media only screen and (min-width: 500px) and (max-width: 767px) {
          float:none;
          margin-top: 8px;
        }
      }
      .vsDate {
        text-align: left;

        @media only screen and (min-width: 500px) and (max-width: 767px) {
          text-align: center;
        }
        p {
          font-size: 14px;
          line-height: 28px;
          color: #3776fd;
          font-weight: 400;
          margin: 0;

          label {
            float: none;
            border: 0;
            margin: 0;
            padding: 0 25px;
            color: #444444;
          }
        }
      }
    }
  }
}
