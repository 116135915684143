@import "../../assets/scss/variables.scss";
.customModal{
    .modal-dialog{
        max-width: 395px;
            // margin: 0 auto;
        .modal-content{
                padding: 24px 0;
                border:none;
                border-radius: 10px;
                position: relative;

                .icon-close{
                    position: absolute;
                    top: 12px;
                    right: 12px;
                }

            .modal-body{
                text-align: center;
                max-width: 292px;
                margin: 0 auto;
                display: table;
                padding: 0;
                
                h4{
                    color:$secondaryColor !important;  
                }
            }
            .modal-footer{
                border:none;
                padding: 0;
                text-align: center;
                display: block;
                .submitBtn{
                    width: 160px;
                    height: 48px;
                }
            }
        }
    }
}
@media only screen and (min-width: 500px) {
    .customModal{
    .modal-dialog{
        margin: 0 auto;
    }
    }
}
