@import "./../../../assets/scss/variables.scss";
.criteriachange {
  width: 100%;
  display: inline-block;
  background: #f6f7fb;
  height: 100vh;
  .agreementPage {
    padding-top: 40px;
    background: $bgColor;
    width: calc(100% - 312px);
    padding-top: 40px;
    height: calc(100vh - 80px);
    display: inline-block;
    @media only screen and (max-width: 1024px) and (min-width: 320px) {
      width: 100% !important;
      display: block !important;
      height: calc(100vh - 0px);
      padding-top: 100px;
    }
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 150px 0 0 0;
      width: 100%;
      @media only screen and (max-width: 1024px) and (min-width: 767px) {
        max-width: 667px;
      }
      @media only screen and (max-width: 766px) and (min-width: 480px) {
        max-width: 452px;
        padding: 0;
      }
      @media only screen and (max-width: 479px) and (min-width: 320px) {
        max-width: 320px;
        padding: 0;
      }
      .img-Container {
        width: 140px;
        height: auto;
        padding-bottom: 40px;
        display: table;
      }
      .content-Section {
        padding-bottom: 25px;
      }
      .checkbox-Section {
        display: table;
        width: 100%;
        margin-bottom: 40px;
        label {
          display: block;
          position: relative;
          font-size: $fontSize16 !important;
          color: $innerColor;
          font-weight: 400 !important;
          margin: 0;
          cursor: pointer;
        }
        span.label-Css {
          background: transparent !important;
          border: none !important;
          display: table !important;
          width: 100% !important;
          position: relative;
          padding-left: 35px;
        }
      }
    }
    .btn {
      margin-bottom: 0px;
    }
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-AgreementPage {
    .agreementPage {
      .main-Container {
        max-width: 731px;
        padding: 120px 0 130px 0;
      }
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-AgreementPage {
    .agreementPage {
      .main-Container {
        max-width: 667px;
        // margin-left: 95px;
        padding: 120px 0 130px 0;
        .content-Section {
          padding-bottom: 15px;
        }
      }
    }
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-AgreementPage {
    display: block !important;
    .agreementPage {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        max-width: 667px;
        padding: 100px 0 137px 0;
        .btn {
          float: left;
        }
        .upgrade-Section {
          padding-top: 0;
          width: 60%;
          float: left;
          padding-left: 61px;
        }
        .checkbox-Section {
          margin-bottom: 25px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-AgreementPage {
    // display: block !important;
    .agreementPage {
      width: 100% !important;
      padding-top: 15px;
      // padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        width: 625px;
        padding: 100px 0 110px 0;
        // margin-left: 10% !important;
        .btn {
          float: left;
        }
        .upgrade-Section {
          padding-top: 25px;
          width: 100%;
          float: left;
          padding-left: 0;
        }
        .checkbox-Section {
          margin-bottom: 25px !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-AgreementPage {
    // display: block !important;
    .agreementPage {
      width: 100% !important;
      padding-top: 15px;
      // padding-bottom: 05px !important;;
      display: table !important;
      .main-Container {
        width: 452px !important;
        padding: 80px 0 90px 0;
        margin-left: 10% !important;
        .btn {
          float: left;
        }
        .upgrade-Section {
          padding-top: 25px;
          width: 100%;
          float: left;
          padding-left: 0;
        }
      }
    }
  }
}
