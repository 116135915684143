@import "./../../../assets/scss/variables.scss";
.main-SimpleSteps{
    display: inline-flex;
    width: 100%;
    .inner-SimpleSteps{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        // overflow: hidden;
        //  overflow-y: scroll;
         width: 100% !important;
        .main-Container{
            max-width: 945px;
            margin: 0 auto;
            padding: 0px 0 10px 0;
            width: 100%;
            // display: table;
            .img-Container{
                width: 140px;
                height: 126px;
                padding-bottom: 40px;
                display: table;
            }
            h2{
                padding-bottom: 30px;
            }
            .content-Section{
                padding-bottom: 40px;
                
                .borderBottom {
                border-bottom: 1px solid $placeholder;
                margin-bottom: 24px;
                &:last-child{
                    margin-bottom: 0;
                }
                h5{
                     padding-bottom: 24px;
                    }
    }
               
            }

        }
        .btn{
            margin-bottom: 0px;
        }
}

    }
  
    @media only screen and (min-width:1280px) and (max-width:1450px) {
        .main-SimpleSteps {
            .inner-SimpleSteps{
                .main-Container{
                    max-width: 731px;
                    padding: 20px 0 40px 0;
                 
                }
            }
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1281px) {
        .main-SimpleSteps {
            .inner-SimpleSteps{
                .main-Container{
                    max-width: 667x;
                    // margin-left:15%;
                    padding: 20px 0 40px 0!important;
                    .content-Section{
                        padding-bottom: 15px !important;
                    }
                   
                }
            }
        }
    }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-SimpleSteps {
            display: block !important;
            .inner-SimpleSteps{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
                .main-Container{
                    max-width: 667px;
                    padding: 20px 0 40px 0;
                    
                    .btn{
                        float: left;
                    }
                
                }
            }
        }
    }
   
    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-SimpleSteps {
            display: block !important;
            .inner-SimpleSteps{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                .main-Container{
                    width: 625px;
                    padding: 10px 0 20px 0;
                }
            }
        }
    }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-SimpleSteps {
            display: block !important;
            .inner-SimpleSteps{
                width: 100% !important;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                 .simplesteps-Scroll{
                    height: calc(100vh - 140px) !important;
                }
                .main-Container{
                    width: 452px !important;
                    padding: 10px 0 20px 0;
                    h2 {
                    font-size: 22px !important;
                    }
                }
            }
        }
    }