@import "./../../../assets/scss/variables.scss";
.main-Welcomepage {
  display: inline-flex;
  width: 100%;

  .inner-Welcomepage {
    // width: calc(100% - 312px);
    // width: -moz-calc(100% - 312px);
    // float: left;
    // display: block;
    display: inline-flex;
    width: 100%;
    height: 100%;
    height: 100vh;
    padding-top: 40px;
    background: $bgColor;
    // overflow: hidden;
    //  overflow-y: scroll;
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      // margin-left: 20%;
      width: 100%;
      height: 100%;
      .content-Section {
        padding-bottom: 40px;
      }
      .upgrade-Section {
        padding-top: 25px;
      }
    }
  }
}

// .main-Welcomepage:not(:root:root) {
//     background: yellow;
//     height: 100%;
//     display: table;
//  }

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//     .main-Welcomepage{
//         height: 100%;
//         height: 100vh;
//         .inner-Welcomepage{
//         height: 100%;
//         height: 100vh;
//       }
//     }
//   }

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-Welcomepage {
    .inner-Welcomepage {
      .main-Container {
        max-width: 667px;
        // margin-left: 13%;
        .content-Section {
          padding-bottom: 15px;
        }
      }
    }
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Welcomepage {
    display: block !important;
    .inner-Welcomepage {
      width: 100% !important;
      padding-top: 15px;
      padding-top: 15px;
      padding-bottom: 55px;
      display: block;
      .main-Container {
        max-width: 667px;
        padding-top: 90px;
        // .btn {
        //   float: left;
        // }
        .upgrade-Section {
          padding-top: 0;
          width: 60%;
          float: left;
          padding-left: 61px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Welcomepage {
    display: block !important;
    .inner-Welcomepage {
      width: 100% !important;
      padding-top: 15px;
      padding-bottom: 55px;
      display: block;
      .main-Container {
        max-width: 625px;
        padding-top: 90px;
        // .btn {
        //   float: left;
        // }
        .upgrade-Section {
          padding-top: 0;
          width: 57%;
          float: left;
          padding-left: 61px;
        }
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Welcomepage {
    display: block !important;
    .inner-Welcomepage {
      width: 100% !important;
      padding-top: 15px;
      padding-bottom: 55px;
      display: block;
      .main-Container {
        width: 442px;
        // .btn {
        //   float: left;
        // }
        .upgrade-Section {
          padding-top: 25px;
          width: 100%;
          float: left;
          padding-left: 0;
        }
      }
    }
  }
}
