@import "../../assets/scss/variables.scss";
.notification{
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 312px;
  text-align: left;
  height:calc(100% - 80px);
  background: $whiteColor;
  top:80px;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  z-index: 1;
  &.show{
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    z-index: 1;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.11);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.11);
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.11);
  }
.main-AdminNotification{
    display: table;
    padding: 16px 0px;
    width: 100%;
    background: $whiteColor;
  .notification-Header{
      padding: 0px 16px;
    .header{
      font-size: $fontSize18 !important;
      padding: 24px 0 18px 0;
    }
    .icon-close{
      font-weight: 500;
    }
  }
  .notificationScroll{
 height: calc(100vh - 180px) !important;
      .notification-Details{
        // padding: 20px 0 8px 0;
       display: block;
        border-bottom: 1px solid $bordeColor;
        margin-bottom: 15px;
        display: block;
        .inner-Details{
             display: block;
              position: relative;
             padding: 0px 16px 8px 16px;
        .innerTitle{
              margin-bottom: 8px;
         &:first-child{
            font-weight: 500;
         }
         .highlighted{
            font-weight: 400;
         }
        }
              h5{
                 font-size: $fontSize14 !important;
              }
          .icon-css{
                height: 30px;
              width: 32px;
              border-radius: 50%;
              background: #eeeeee;
              position: absolute;
              left: 16px;
              top: 0px;
              &:before{
                content: '\e906';
              font-family: 'oralift';
              position: absolute;
              top: 8px;
              left: 10px;
              color: #590459;
              }
          }
        }
      }
    }
}

}
@media only screen and (min-width:993px) and (max-width:1024px) {
.notification{
  max-width: 250px;
}
}
@media only screen and (min-width:768px) and (max-width:992px){
  .notification{
  max-width: 250px;
}
}
@media only screen and (max-width:767px){
 .notification{
  max-width: 226px;
}
}
