
@import "./../../assets/scss/variables.scss";
.main-GenerateCode {
	width: 100%;
	display: inline-block;
	background: $bgColor;
	height: 100vh;
	.inner-Profile {
		display: inline-block;
		width: 100%;
    height: 100vh;
    height: calc(100vh - 80px);
		.main-Container {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			display: table;
			width: 100%;
      margin-top: 20px;      
			.generateCode {
				background-color: $whiteColor;
				max-width: 385px;
				padding: 20px;
				width: 100%;
				margin: 0 auto;
        	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
						-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
						-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
				.form {
					align-items: center;
					.textFiled {
						margin-bottom: 30px;
					}
				}
			}
			.codeResult {
        position: relative;
        padding-top: 70px;
        	.downloadBtn {
            	position: absolute;
					right: 19px;
					top: 20px;
					color: $secondaryColor;
					button {
						text-transform: capitalize;
						width: 120px;
						line-height: 20px;
						height: 30px;
						padding: 0;
            font-weight: 400;
            font-size: 14px;
					}
				}
				.codeUl {
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					list-style-type: none;
					margin: 0;
					padding: 0 11px;
					li {
            width: 10%;
            	padding: 0 7px;
            .resultTxt{
						width: 100%;
						float: left;
						margin-bottom: 15px;
						background: #ffffff;
						justify-content: center;
						align-items: center;
						display: flex;
						box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
						-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
						-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
						border-radius: 4px;
						border: 1px solid #e9ebf2;
						padding: 12px;
						float: left;
						word-break: break-all;
            cursor: pointer;
            p{
              margin: 0;
              font-size: 14px;
            }
          }
					}
				}
			
			}
		}
}
}



@media only screen and (min-width: 992px) and (max-width: 1024px) {
.main-GenerateCode {
  .inner-Profile {
    .main-Container{
       .codeResult {
         .codeUl{
            li {
                width: 14.2%;
                    // &:nth-of-type(7n+0) {
                    //             padding-right: 0px;
                    //         }
                  
            }
         }
          .downloadBtn{
               right: 35px;
         }
       }
    }
}
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.main-GenerateCode {
  .inner-Profile {
    .main-Container{
       .codeResult {
         .codeUl{
            li {
                width: 14.2%;
                    // &:nth-of-type(7n+0) {
                    //             padding-right: 0px;
                    //         }
                  
            }
         }
          .downloadBtn{
               right: 35px;
         }
       }
    }
}
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.main-GenerateCode {
  .inner-Profile {
    .main-Container{
       .codeResult {
         .codeUl{
            li {
                width: 16.5%;
                  
                  
            }
         }
          .downloadBtn{
               right: 30px;
         }
       }
    }
}
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
	.main-GenerateCode {
  .inner-Profile {
    .main-Container{
       .codeResult {
         .codeUl{
            li {
                width: 20%;
                  
            }
         }
          .downloadBtn{
               right: 20px;
         }
       }
    }
}
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
	.main-GenerateCode {
  .inner-Profile {
    .main-Container{
       .codeResult {
         .codeUl{
            li {
                width: 33.33%;
                
                  
            }
         }
          .downloadBtn{
               right: 20px;
         }
       }
    }
}
  }
}