@import "./../../../assets/scss/variables.scss";
.main-ManualTest {
  display: inline-flex;
  width: 100%;
  .inner-ManualTest {
    display: inline-flex;
    width: 100%;
    height: 100%;
    height: 100vh;
    padding-top: 40px;
    background: $bgColor;
    // overflow: hidden;
    //  overflow-y: scroll;
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 210px 0 0px 0;
      width: 100%;
      // display: table;
      .img-Container {
        padding-bottom: 40px;
        display: table;
      }

      .question-Form {
        padding-top: 15px;
        padding-bottom: 40px;

        .customMask {
          background: none !important;
          border: none !important;
          border-bottom: 1px solid #d8d8d8 !important;
          border-radius: 0;
          padding: 0;
          font-size: 16px;
          color: #444444;
          font-weight: 500;
          font-family: "Roboto";
          width: 100%;
          outline: none;
        }
        .form-group {
          margin: 0;
        }
      }
    }
    .btn {
      margin-bottom: 0px;
    }
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-ManualTest {
    .inner-ManualTest {
      .main-Container {
        max-width: 731px;
        padding: 220px 0 0 0;
      }
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-ManualTest {
    .inner-ManualTest {
      .main-Container {
        max-width:667px;
        padding: 220px 0 0x 0;
        .content-Section {
          padding-bottom: 15px;
        }
      }
    }
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-ManualTest {
    display: block !important;
    .inner-ManualTest {
      width: 100%;
      padding-top: 15px;
      display: table !important;
      .main-Container {
        max-width: 667px;
        padding: 220px 0 0 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-ManualTest {
    display: block !important;
    .inner-ManualTest {
      width: 100% !important;
      padding-top: 15px;
      display: block !important;
      .main-Container {
        width: 625px;
        padding: 200px 0 0 0;
      }
    }
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-ManualTest {
    display: block !important;
    .inner-ManualTest {
      width: 100% !important;
      padding-top: 15px;
      display: table !important;
      .main-Container {
        width: 452px !important;
        padding: 200px 0 0 0;
        h1 {
          font-size: $fontSize24 !important;
        }
      }
    }
  }
}
