@import "../../assets/scss/variables.scss";
.submitBtn{
background: rgba(216,77,149,1);
background: -moz-linear-gradient(170.73deg, #D84D95 5.07%, #590459 94.64%);
background: -webkit-linear-gradient(170.73deg, #D84D95 5.07%, #590459 94.64%);
background: -o-linear-gradient(170.73deg, #D84D95 5.07%, #590459 94.64%);
background: -ms-linear-gradient(170.73deg, #D84D95 5.07%, #590459 94.64%);
background: linear-gradient(170.73deg, #D84D95 5.07%, #590459 94.64%);
background: linear-gradient(170.73deg, #D84D95 5.07%, #590459 94.64%);
width: 260px;
border-radius: 45px  !important;
border: none !important;
height: 56px;
text-transform: uppercase;
position: relative;
&:focus{
    outline: none !important;
    box-shadow: none !important;
}
&.active{
    box-shadow: none !important;
}
.loaderImg{
    position: absolute;
    top: 0;
    left: 38%;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    right: 38%;
    
}
}