@import "./../../../assets/scss/variables.scss";

.main-Home5TouchTest {
  width: 100%;
  display: inline-block;
  background: $bgColor;
  height: 100vh;
  .inner-5TouchTest {
    height: calc(100vh - 80px);
    display: inline-block;
    width: calc(100% - 312px);
    .main-Container {
      max-width: 945px;
      margin: 0 auto;
      padding: 40px 0 10px 0;
      width: 100%;
      display: table;
      position: relative;

      .dropdown-Section {
        max-width: 383px;
        width: 100%;
        margin: 0 auto;
        display: table;
        padding-bottom: 45px;
        padding-left: 10px;

        &.toolhome {
          .inner-Dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &.homeFivemm {
              .customMinute {
                width: 30%;
                border-radius: 50px;
                padding: 0 10px 0 10px;
                border: 1px solid #d8d8d8;
                height: 35px;
                outline: none;
                background: #f6f7fb;
                margin-left: 10px;
                text-align: center;
              }
              .error-msg {
                position: absolute;
                bottom: -30px;
                left: 30px;
              }
              .divclass{
                &.show {
                  position: fixed;
                  width: calc(100% - 312px);
                  height: calc(100vh - 80px);
                  background: #f3f3f314;
                  bottom: 0;
                }
              }
              .tool-Tips {
                
                &.show{
                  a {
                    span{
                      display: block !important; 
                     }
                }
                }
                a {
                    span{
                      display: none !important; 
                     }
                }
              }
            }
            .aLink {
              font-size: 13px !important;
              line-height: 20px;
            }
          }
          .dropdowncomponent {
            width: 30%;
            border-radius: 50px;
            padding: 0 10px 0 10px;
            border: 1px solid $bordeColor;
            &:nth-child(2) {
              margin-left: 10px;
            }
            &:after {
              content: "";
              width: 1px;
              height: 34px;
              background: $bordeColor;
              position: absolute;
              top: 0;
              right: 30px;
            }
            .dropdownButtom {
              button {
                &.dropdown-toggle {
                  &:before {
                    top: 7px;
                  }
                  color: $innerColor;
                }
              }
            }
          }
        }
        // .inputminute {

        // }
      }

      .dueOptions {
        position: absolute;
        left: 0%;
        top: 30%;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: block;
            font-size: 16px;
            line-height: 20px;
            padding-bottom: 24px;
            a{
              color: #212529;
              text-decoration: none;

              &.list-iconlink{
                text-decoration: underline;
              }

              // span{
              //   &.list-icon{
              //     &.active{
              //       text-decoration: underline;
              //     }
              //   }
              // }
            }

            .list-icon {
              height: 12px;
              width: 12px;
              border-radius: 50%;
              background: $lightwhite;
              border: 1px solid $lightwhite;
              display: inline-block;
              margin-right: 8px;
              vertical-align: inherit;

              &.active {
                background: $blueColor;
                border: 1px solid $blueColor;
              }
            }
          }
        }
      }

      .timer-Progessbar {
        position: relative;
        display: table;
        margin: 0 auto;
      }

      .paddingB {
        padding-bottom: 90px;
      }

      .bottom-Section {
        padding-top: 24px;

        span {
          display: block;
        }

        .aLink {
          padding-top: 20px;
          padding-bottom: 10px;
        }
        .disableLink{
          padding-top: 20px;
          padding-bottom: 10px;
          color: #212529;
        }
      .submitBtn{
          &:nth-child(2){
        margin: 0 auto;
        display: table;
        margin-top: 15px;
        background: white;
        font-weight: bold;
        line-height: 25px;
        color: #590459;
        box-shadow: 0px 0px 15px 0px rgba(215, 215, 215, 0.61);
        width: 210px;
          }
          &:last-child{
        margin-top: 15px;
        }
      }
      }
    }

    .btn {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1450px) {
  .main-Home5TouchTest {
    .inner-5TouchTest {
      .main-Container {
        max-width: 731px;

        .dueOptions {
          ul {
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1281px) {
  .main-Home5TouchTest {
    .inner-5TouchTest {
      .main-Container {
        max-width: 667px;
        // margin-left: 95px;
        // padding: 70px 0 72px 0;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-Home5TouchTest {
    display: block !important;
    .inner-5TouchTest {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 55px !important;
      display: block !important;
      .main-Container {
        max-width: 667px;
        .paddingB {
          padding-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-Home5TouchTest {
    .inner-5TouchTest {
      .main-Container {
        .dropdown-Section {
          margin-top: 70px;
        }

        .dueOptions {
          top: 0;
          width: 100%;
          display: block;
          text-align: center;
          margin: 30px 0;

          ul {
            li {
              display: inline-block;
              padding-bottom: 0;
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-Home5TouchTest {
    display: block !important;

    .inner-5TouchTest {
      width: 100% !important;
      // padding-top: 15px;
      // padding-bottom: 55px !important;
      display: block !important;

      .main-Container {
        width: 625px;
        // padding: 85px 0 0px 0;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .main-Home5TouchTest {
    display: block !important;

    .inner-5TouchTest {
      width: 100% !important;
      // padding-top: 15px;
      padding-bottom: 0px !important;
      display: table !important;

      .main-Container {
        width: 452px !important;
        // padding: 30px 0 0px 0;

        .paddingB {
          padding-bottom: 40px;
        }
      }
    }
  }
}

.hide-dashboard-wear-clock{
  pointer-events: none;
  opacity: 0.5;
  content: "ffffffffffffffff"
}

// disable current

// .inner-5TouchTest{position: relative; }
// .hide-dashboard-wear-clock::after { content: ""; opacity: 0.5; background: #000; } 
