.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white !important;
}

.App-link {
  color: #09d3ac;
}
p{
  font-size: 50px;
  font-weight: 600;
}

@media screen and (max-width:767px) {
  .headerhome { display: none; }
  .highcharts-container { width: 100% !important; }
}