@import "./../../../assets/scss/variables.scss";
.main-Congratulations{
    display: inline-flex;
    width: 100%;
    .inner-Congratulations{
        display: inline-flex;
        width: 100%;
        height: 100%;
        height: 100vh;
        padding-top: 40px;
        background: $bgColor;
        .step-Container{
            position: relative;

            .complete-step{
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;

                .img-Container{
                    width: 100%;
                    height: 100%;
                    padding-bottom: 32px;
                    display: table;
                }
                .content-Section{
                 width: calc(100% - 90px);
                 margin: 0 auto;
                }
            }
           
        }
}
}
    @media only screen and (min-width:1025px) and (max-width:1281px) {
                    .content-Section{
                        padding-bottom: 15px;
                    }
                }
    @media only screen and (min-width:993px) and (max-width:1024px) {
        .main-Congratulations {
            display: block !important;
            .inner-Congratulations{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 55px!important;
                display: block!important;
            }
        }
    }
   
    @media (max-width: 1024px){
        .main-Congratulations{
            .inner-Congratulations {
                .step-Container {
                    .complete-step {
                        .content-Section{
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }
                
            }
        }

        
    }

    @media only screen and (min-width:768px) and (max-width:992px) {
        .main-Congratulations {
            display: block !important;
            .inner-Congratulations{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 55px !important;
                display: block !important;
                }
            }
        }
    @media only screen and (min-width:500px) and (max-width:767px) {
        .main-Congratulations {
            display: block !important;
            .inner-Congratulations{
                width: 100% !important;;
                padding-top: 15px;
                padding-bottom: 05px !important;;
                display: table!important;
            }
        }
    }